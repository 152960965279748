import { Literal, Static, Union } from "runtypes";

import { assertNever } from "./errors";
import { getNormalEnum } from "./runtypeEnums";

export type EnumValues<T> = T[keyof T];

export const OutputType = {
  STREAM: "STREAM",
  ERROR: "ERROR",
  EXECUTE_RESULT: "EXECUTE_RESULT",
  METADATA: "METADATA",
} as const;
export type OutputType = EnumValues<typeof OutputType>;

export const AppRunState = {
  RUNNING: "RUNNING",
  IDLE: "IDLE",
  STALE: "STALE",
  ERRORED: "ERRORED",
  OUT_OF_KERNELS: "OUT_OF_KERNELS",
  OVER_USER_KERNEL_LIMIT: "OVER_USER_KERNEL_LIMIT",
  KILLED: "KILLED",
  PAID_COMPUTE_DISABLED: "PAID_COMPUTE_DISABLED",
} as const;
export type AppRunState = EnumValues<typeof AppRunState>;

/**
 * The simplest way to introduce an additional error state was expanding
 * AppRunState, though alternatives were considered. Use this utility for
 * determining whether a given run state is 'erroneous', though by and large
 * OUT_OF_KERNELS is limited to new and unvetted users of the application who
 * hit resource limitations.
 *
 * See GROW-#### for context.
 */
export const isAppRunStateError = (appRunState: AppRunState): boolean =>
  (
    [
      AppRunState.ERRORED,
      AppRunState.OUT_OF_KERNELS,
      AppRunState.PAID_COMPUTE_DISABLED,
      AppRunState.OVER_USER_KERNEL_LIMIT,
    ] as AppRunState[]
  ).includes(appRunState);

// TODO(riley): consolidate these three lists with isAppRunStateError once determined if its OK to update isAppRunStateError
// I will need these to display scheduled run results on the client, so until then will keep these lists to ensure AppRunState is exhaustive.
export const FAILED_APP_RUN_STATES = [
  AppRunState.ERRORED,
  AppRunState.OUT_OF_KERNELS,
  AppRunState.KILLED,
  AppRunState.OVER_USER_KERNEL_LIMIT,
  AppRunState.PAID_COMPUTE_DISABLED,
];
export const SUCCESS_APP_RUN_STATES = [AppRunState.IDLE, AppRunState.STALE];
export const PENDING_APP_RUN_STATES = [AppRunState.RUNNING];
type CoveredStates =
  | (typeof FAILED_APP_RUN_STATES)[number]
  | (typeof SUCCESS_APP_RUN_STATES)[number]
  | (typeof PENDING_APP_RUN_STATES)[number];
// if assertcovered fails when adding a new state, make sure to update one of the above lists.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertCovered(state: AppRunState): CoveredStates {
  return state;
}

export const isPendingAppRunState = (appRunState: AppRunState): boolean =>
  (PENDING_APP_RUN_STATES as AppRunState[]).includes(appRunState);

export const KernelStateEnumLiterals = Union(
  Literal("STARTING"),
  Literal("RUNNING"),
  Literal("STOPPING"),
  Literal("STOPPED"),
  Literal("ERRORED"),
  Literal("OOM_KILLED"),
);

export const KernelState = getNormalEnum(KernelStateEnumLiterals);
export type KernelState = Static<typeof KernelStateEnumLiterals>;

export const KernelFileDownloadState = {
  DONE: "DONE",
  IN_PROGRESS: "IN_PROGRESS",
  FAILED: "FAILED",
} as const;
export type KernelFileDownloadState = EnumValues<
  typeof KernelFileDownloadState
>;

export const FileUploadState = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  CANCELLED: "CANCELLED",
  ERRORED: "ERRORED",
} as const;
export type FileUploadState = EnumValues<typeof FileUploadState>;

export const CellExecutionState = {
  RUNNING: "RUNNING",
  IDLE: "IDLE",
  QUEUED: "QUEUED",
  ERRORED: "ERRORED",
  CANCELLED: "CANCELLED",
  STALE: "STALE",
} as const;
export type CellExecutionState = EnumValues<typeof CellExecutionState>;

export const CellType = {
  CODE: "CODE",
  MARKDOWN: "MARKDOWN",
  DISPLAY_TABLE: "DISPLAY_TABLE",
  INPUT: "INPUT",
  SQL: "SQL",
  VEGA_CHART: "VEGA_CHART",
  CELL_GROUP: "CELL_GROUP",
  METRIC: "METRIC",
  TEXT: "TEXT",
  MAP: "MAP",
  WRITEBACK: "WRITEBACK",
  DBT_METRIC: "DBT_METRIC",
  PIVOT: "PIVOT",
  FILTER: "FILTER",
  COMPONENT_IMPORT: "COMPONENT_IMPORT",
  CHART: "CHART",
  BLOCK: "BLOCK",
  EXPLORE: "EXPLORE",
  COLLAPSIBLE: "COLLAPSIBLE",
  // hygen-inject: cell-type-enum
} as const;
export type CellType = EnumValues<typeof CellType>;

export const CellTypeToDisplayName: Record<CellType, string> = {
  [CellType.INPUT]: "Input",
  [CellType.MARKDOWN]: "Markdown",
  [CellType.CODE]: "Code",
  [CellType.SQL]: "SQL",
  [CellType.VEGA_CHART]: "Chart (legacy)",
  [CellType.DISPLAY_TABLE]: "Table",
  [CellType.METRIC]: "Single value",
  [CellType.CELL_GROUP]: "Cell Group",
  [CellType.TEXT]: "Text",
  [CellType.MAP]: "Map",
  [CellType.WRITEBACK]: "Writeback",
  [CellType.DBT_METRIC]: "dbt Semantic Layer",
  [CellType.PIVOT]: "Pivot",
  [CellType.FILTER]: "Filter",
  [CellType.COMPONENT_IMPORT]: "Component",
  [CellType.CHART]: "Chart",
  [CellType.BLOCK]: "Block",
  [CellType.EXPLORE]: "Explore",
  [CellType.COLLAPSIBLE]: "Section",
  // hygen-inject: cell-type-display-name
};

export const CommentableEntity = {
  CELL: "CELL",
  COMMENT: "COMMENT",
} as const;
export type CommentableEntity = EnumValues<typeof CommentableEntity>;

export const SpecialVersionType = {
  LAST_PUBLISHED: "latest",
  DRAFT: "draft",
} as const;
export type SpecialVersionType = EnumValues<typeof SpecialVersionType>;

/**
 * An intrinsic group is one that does not actually exist in the database,
 * rather membership is determined by user attributes
 * at runtime
 */
export const IntrinsicGroupId = {
  /**
   * Includes users in a workspace at org role MEMBER and above
   */
  MEMBERS: "MEMBERS",
  /**
   * Includes users in a workspace only at org role GUEST
   */
  GUESTS: "GUESTS",
  /**
   * Includes users in a workspace at org role ANONYMOUS and above
   */
  PUBLIC: "PUBLIC",
} as const;
export type IntrinsicGroupId = EnumValues<typeof IntrinsicGroupId>;

/**
 * Roles for users across an entire deployment of hex.
 */
export const PlatformRole = {
  /**
   * @deprecated Super admin that can create/edit all orgs.
   * Please avoid using PlatformRole.ADMIN. While not officially deprecated, we are working to minimize platform admin's importance in favor of a new
   * auth mechanism that will allow plat admins additional capabilities. Please check in with the auth team before using this role.
   *
   */
  ADMIN: "ADMIN",
  /**
   * Everyone else.
   */
  MEMBER: "MEMBER",
} as const;
export type PlatformRole = EnumValues<typeof PlatformRole>;

/**
 * Roles for users in an org, in descending order of permission scope.
 *
 * Each role's capabilities are generally a superset of the ones beneath it.
 */
export const OrgRoleLiteral = Union(
  /**
   * Can edit org membership and workspace assets.
   */
  Literal("ADMIN"),
  /**
   * Can create and edit projects.
   * Has some limited org-level permissions.
   */
  Literal("MANAGER"),
  /**
   * Able to create and edit projects.
   *
   * Also referred to as Author.
   */
  Literal("EDITOR"),
  /**
   * Able to create "Explores"
   */
  Literal("EXPLORER"),
  /**
   * Able to view projects if given Role.USER or Role.VIEWER perms
   */
  Literal("MEMBER"),
  /**
   * Someone who is not part of the org, only has access to projects/assets
   * explicility shared with them.
   */
  Literal("GUEST"),
  /**
   * Ephemeral user for viewing publicly shared Hexes
   */
  Literal("ANONYMOUS"),
);

export type OrgRole = Static<typeof OrgRoleLiteral>;
export const OrgRole = getNormalEnum(OrgRoleLiteral);

/**
 * Roles for users in a group, in descending order of permission scope.
 *
 * Each role's capabilities are generally a superset of the ones beneath it.
 */
export const GroupRole = {
  /**
   * Can create/view projects in group
   */
  MEMBER: "MEMBER",
} as const;
export type GroupRole = EnumValues<typeof GroupRole>;

/**
 * Roles that a user or group can have on a project
 */
export const ProjectRoleLiteral = Union(
  /**
   * Can edit, change permissions, and delete
   */
  Literal("OWNER"),
  /**
   * Can edit logic. Can also change permissions if allowed by OWNER
   */
  Literal("EDITOR"),
  /**
   * Can view logic
   */
  Literal("VIEWER"),
  /**
   * Can only run the published app
   */
  Literal("APP_USER"),
);
export type ProjectRole = Static<typeof ProjectRoleLiteral>;
export const ProjectRole = getNormalEnum(ProjectRoleLiteral);

/**
 * Roles that a user or group can have on an app session
 *
 * @deprecated The app session role for every grant will always be an owner.
 * If the user is the creator of the app session, assume that they are the OWNER of the app session.
 */
export const AppSessionRole = {
  /**
   * Can run/edit and change share setting of the app session
   *
   * This is granted to the creator of an app session.
   */
  OWNER: "OWNER",
} as const;
export type AppSessionRole = EnumValues<typeof AppSessionRole>;

/**
 * Roles that a user or group can have on a shared asset
 */
export const SharedAssetRole = {
  /**
   * Can import the asset into a project and write code using the asset
   */
  IMPORTER: "IMPORTER",

  /**
   * Can view results derived from the shared asset (e.g. query results in app view).
   *
   * Semantically closer to `DERIVED_RESULTS_VIEWER`.
   */
  VIEWER: "VIEWER",
} as const;
export type SharedAssetRole = EnumValues<typeof SharedAssetRole>;

/**
 * Roles that a user or group can have on a Collection
 */
export const CollectionRole = {
  /**
   * Can edit the collection, modify collection membership, and add/remove projects from the collection
   * Has ProjectRole defined on the hex_collection_link for each project within the collection
   */
  MANAGER: "MANAGER",

  /**
   * Has ProjectRole defined on the hex_collection_link for each project within the collection
   */
  MEMBER: "MEMBER",
} as const;
export type CollectionRole = EnumValues<typeof CollectionRole>;

/**
 * Roles on entities, _always_ check the underlying entity for what each role means in context
 */
export const Role = {
  /**
   * See underlying entity docs for role meaning in context
   */
  OWNER: "OWNER",
  /**
   * See underlying entity docs for role meaning in context
   */
  EDITOR: "EDITOR",
  /**
   * See underlying entity docs for role meaning in context
   */
  VIEWER: "VIEWER",
  /**
   * See underlying entity docs for role meaning in context
   */
  USER: "USER",
} as const;
export type Role = EnumValues<typeof Role>;

export const LayoutType = {
  CANVAS: "CANVAS",
  GRID: "GRID",
  STORY: "STORY",
} as const;
export type LayoutType = EnumValues<typeof LayoutType>;

export const CellRunModeLiterals = Union(
  Literal("CELL_ONLY"),
  Literal("CELL_AND_ABOVE"),
  Literal("CELL_AND_BELOW"),
  Literal("ALL_CELLS"),
);

export const CellRunMode = getNormalEnum(CellRunModeLiterals);
export type CellRunMode = Static<typeof CellRunModeLiterals>;

export const ProjectLanguageLiteral = Union(Literal("PYTHON"), Literal("R"));
export type ProjectLanguage = Static<typeof ProjectLanguageLiteral>;
export const ProjectLanguage = getNormalEnum(ProjectLanguageLiteral);

export const ProjectEmbeddingStatusLiteral = Union(
  Literal("NOT_PROCESSED"), // Default status. It means that the project has never been checked for embedding status.
  Literal("PROCESSING"), // Status when the project is being checked for embedding status.
  Literal("EMBEDDED"), // Status of the Hex when it is embedded or represented in LanceTableOfHexes.
  Literal("NOT_EMBEDDED"), // Status of the Hex when it is not embedded or represented in LanceTableOfHexes.
  Literal("TO_BE_EMBEDDED"), // Status of the Hex when it is considered "high quality" and will be picked up to be embedded in Lance.
  Literal("TO_BE_DELETED"), // Status of the Hex when it is considered "low quality" or is deleted from the workspace. This will eventually be deleted from the Lance table during garbage collection process.
  Literal("PROCESSING_DELETE"), // Status of the Hex when it is being processed by Lance GC queue and deleted from the Lance table.
);
export type ProjectEmbeddingStatus = Static<
  typeof ProjectEmbeddingStatusLiteral
>;
export const ProjectEmbeddingStatus = getNormalEnum(
  ProjectEmbeddingStatusLiteral,
);

// This is a hack. We will want to figure out what we want to do long term
export const ProjectLanguageLiteralLowercase = Union(
  Literal("python"),
  Literal("r"),
);
export type ProjectLanguageLowercase = Static<
  typeof ProjectLanguageLiteralLowercase
>;
export const ProjectLanguageLowercase = getNormalEnum(
  ProjectLanguageLiteralLowercase,
);

export const CellOutputType = {
  PANDAS: "PANDAS",
  SNOWPARK: "SNOWPARK",
  BIGFRAMES: "BIGFRAMES",
} as const;
export type CellOutputType = EnumValues<typeof CellOutputType>;

export const SchemaElementSearchResultType = {
  DATABASES: "DATABASES",
  SCHEMAS: "SCHEMAS",
  TABLES: "TABLES",
  COLUMNS: "COLUMNS",
} as const;
export type SchemaElementSearchResultType = EnumValues<
  typeof SchemaElementSearchResultType
>;

export const SchemaElementSearchAlgorithm = {
  SUBSTRING: "SUBSTRING",
  PREFIX: "PREFIX",
  EXACT: "EXACT",
} as const;
export type SchemaElementSearchAlgorithm = EnumValues<
  typeof SchemaElementSearchAlgorithm
>;

export const HexOwnershipLevel = {
  /**
   * Projects created by the querying user (TODO: should this be called creator or self instead?)
   */
  OWN: "OWN",
  /**
   * Projects shared with the querying user via grant to the user, one of their groups, or via a collection
   */
  SHARED: "SHARED",
  /**
   * Projects shared with the querying user's org or through a collection shared with the querying user's org
   */
  ORG: "ORG",
  /**
   * Projects that the querying user can access via any ownership or sharing method
   */
  ALL: "ALL",
} as const;
export type HexOwnershipLevel = EnumValues<typeof HexOwnershipLevel>;

export const HexFilter = {
  ONLY: "ONLY",
  EXCLUDE: "EXCLUDE",
  INCLUDE: "INCLUDE",
} as const;
export type HexFilter = EnumValues<typeof HexFilter>;

export const ArchiveFilter = {
  ONLY: "ONLY",
  EXCLUDE: "EXCLUDE",
  INCLUDE: "INCLUDE",
} as const;
export type ArchiveFilter = EnumValues<typeof ArchiveFilter>;

export const TrashedFilter = {
  ONLY: "ONLY",
  EXCLUDE: "EXCLUDE",
  INCLUDE: "INCLUDE",
} as const;
export type TrashedFilter = EnumValues<typeof TrashedFilter>;

export const UserActiveFilter = {
  ONLY_ACTIVE: "ONLY_ACTIVE",
  ONLY_INACTIVE: "ONLY_INACTIVE",
  BOTH: "BOTH",
} as const;
export type UserActiveFilter = EnumValues<typeof UserActiveFilter>;

export const PendingReviewFilter = {
  MY_REVIEW: "MY_REVIEW",
  ANY_REVIEW: "ANY_REVIEW",
  UNFILTERED: "UNFILTERED",
} as const;
export type PendingReviewFilter = EnumValues<typeof PendingReviewFilter>;

export const HexOrder = {
  LAST_EDIT: "LAST_EDIT",
  LAST_PUBLISH: "LAST_PUBLISH",
  RECENTLY_VIEWED: "RECENTLY_VIEWED",
  APP_ONLY_RECENTLY_VIEWED: "APP_ONLY_RECENTLY_VIEWED",
  LAST_SEVEN_DAYS_VIEW_COUNT: "LAST_SEVEN_DAYS_VIEW_COUNT",
  LAST_FOURTEEN_DAY_VIEW_COUNT: "LAST_FOURTEEN_DAY_VIEW_COUNT",
  LAST_THIRTY_DAY_VIEW_COUNT: "LAST_THIRTY_DAY_VIEW_COUNT",
  TOTAL_VIEW_COUNT: "TOTAL_VIEW_COUNT",
  CREATED_AT: "CREATED_AT",
  PROJECT_NAME: "PROJECT_NAME",
  OWNER: "OWNER",
  CREATOR: "CREATOR",
  STATUS: "STATUS",
  CATEGORY: "CATEGORY",
  ARCHIVED_AT: "ARCHIVED_AT",
  MY_ACCESS: "MY_ACCESS",
} as const;
export type HexOrder = EnumValues<typeof HexOrder>;

export const LastRunStatus = {
  SUCCESS: "SUCCESS",
  /**
   * failed at maxAttempts, no more attempts in progress
   */
  FAILED: "FAILED",
  /**
   * if the current run is in progress, or the previous failed and the run is retrying
   */
  PENDING: "PENDING",
} as const;
export type LastRunStatus = EnumValues<typeof LastRunStatus>;

export const ScheduleEnabled = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
} as const;
export type ScheduleEnabled = EnumValues<typeof ScheduleEnabled>;

export const AOLogOrder = {
  NEW_FIRST: "NEW_FIRST",
  OLD_FIRST: "OLD_FIRST",
} as const;
export type AOLogOrder = EnumValues<typeof AOLogOrder>;

export const HexVersionHistoryEventFilter = {
  VERSION: "VERSION",
  EDIT: "EDIT",
} as const;
export type HexVersionHistoryEventFilter = EnumValues<
  typeof HexVersionHistoryEventFilter
>;

export const HexVersionHistoryVersionFilter = {
  REVIEWED: "REVIEWED",
  PUBLISHED: "PUBLISHED",
} as const;
export type HexVersionHistoryVersionFilter = EnumValues<
  typeof HexVersionHistoryVersionFilter
>;

export const HexColor = {
  RED: "RED",
  ORANGE: "ORANGE",
  YELLOW: "YELLOW",
  LIME: "LIME",
  GREEN: "GREEN",
  TEAL: "TEAL",
  BLUE: "BLUE",
  PURPLE: "PURPLE",
  PINK: "PINK",
  GRAY: "GRAY",
} as const;
export type HexColor = EnumValues<typeof HexColor>;

export const CacheSource = {
  LOGIC_VIEW: "LOGIC_VIEW",
  SCHEDULED_RUN: "SCHEDULED_RUN",
  APP_LOAD: "APP_LOAD",
} as const;
export type CacheSource = EnumValues<typeof CacheSource>;

export const MetricCellComparisonType = {
  VALUE: "VALUE",
  PERCENTAGE: "PERCENTAGE",
  ABSOLUTE: "ABSOLUTE",
} as const;
export type MetricCellComparisonType = EnumValues<
  typeof MetricCellComparisonType
>;

export const MetricCellComparisonFormat = {
  POSITIVE_NEGATIVE: "POSITIVE_NEGATIVE",
  INVERTED_POSITIVE_NEGATIVE: "INVERTED_POSITIVE_NEGATIVE",
} as const;
export type MetricCellComparisonFormat = EnumValues<
  typeof MetricCellComparisonFormat
>;

export const CustomerType = {
  CUSTOMER: "CUSTOMER",
  TEST: "TEST",
  SINGLE_TENANT: "SINGLE_TENANT",
} as const;
export type CustomerType = EnumValues<typeof CustomerType>;

export const VcsConnectionType = {
  GITHUB_SYNC: "GITHUB_SYNC",
  GITLAB_SYNC: "GITLAB_SYNC",
  BITBUCKET_SYNC: "BITBUCKET_SYNC",
  GITHUB_SERVER_SYNC: "GITHUB_SERVER_SYNC",
  GITHUB: "GITHUB", // For GitHub package import
  GITHUB_SERVER_PACKAGE_IMPORT: "GITHUB_SERVER_PACKAGE_IMPORT",
  GITLAB_PACKAGE_IMPORT: "GITLAB_PACKAGE_IMPORT",
  BITBUCKET_PACKAGE_IMPORT: "BITBUCKET_PACKAGE_IMPORT",
} as const;
export type VcsConnectionType = EnumValues<typeof VcsConnectionType>;

// Used for both Github and Gitlab
export const GithubRefType = {
  BRANCH: "BRANCH",
  TAG: "TAG",
  COMMIT: "COMMIT",
} as const;
export type GithubRefType = EnumValues<typeof GithubRefType>;

export const AppMetadataField = {
  /** @deprecated use "name" */
  TITLE: "TITLE",
  NAME: "NAME",
  DESCRIPTION: "DESCRIPTION",
  AUTHOR: "AUTHOR",
  LAST_EDITED: "LAST_EDITED",
  LAST_RUN: "LAST_RUN",
  CATEGORIES: "CATEGORIES",
  STATUS: "STATUS",
  TABLE_OF_CONTENTS: "TABLE_OF_CONTENTS",
} as const;
export type AppMetadataField = EnumValues<typeof AppMetadataField>;

export const GridElementType = {
  CELL: "CELL",
  SHARED_FILTER: "SHARED_FILTER",
} as const;
export type GridElementType = EnumValues<typeof GridElementType>;

export const ScheduledRunNotificationType = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  ALL: "ALL",
} as const;
export type ScheduledRunNotificationType = EnumValues<
  typeof ScheduledRunNotificationType
>;

export const ScreenshotFormatLiteral = Union(Literal("png"), Literal("pdf"));
export type ScreenshotFormat = Static<typeof ScreenshotFormatLiteral>;
export const ScreenshotFormat = getNormalEnum(ScreenshotFormatLiteral);

export const NotificationDestination = {
  SLACK_CHANNEL: "SLACK_CHANNEL",
  /**
   * When this is the notification destination type, we use the Hex user's notification preferences to determine how to deliver the notification.
   */
  USER_PREFERENCE: "USER_PREFERENCE",
} as const;
export type NotificationDestination = EnumValues<
  typeof NotificationDestination
>;

export const NotificationTypeLiteral = Union(
  Literal("COMMENT"),
  Literal("PROJECT_SHARE"),
  Literal("HEX_ROLE_REQUEST"),
  Literal("ORG_ROLE_REQUEST"),
  Literal("SCHEDULED_RUN_FAILURE"),
  Literal("SCHEDULED_RUN_SUCCESS"),
  Literal("ACCOUNT_CREATION"),
  Literal("TEXT_CELL_MENTION"),
  Literal("INITIAL_TOKEN_EXPIRATION"),
  Literal("FINAL_TOKEN_EXPIRATION"),
  Literal("ADMIN_HEX_ROLE_GRANTED"),
  Literal("CONNECTED_APP"),
  Literal("DATA_CREDENTIAL_REQUEST"),
  Literal("MANUAL_ORG_CREATION"),
  Literal("ARCHIVE_DIGEST"),
  Literal("PROJECT_LIFECYCLE"),
  Literal("REVIEW_REQUESTED"),
  Literal("REVIEW_CREATED"),
  Literal("REVIEW_REQUEST_RESOLVED"),
  Literal("COMPUTE_SPEND_LIMIT"),
);

export const NotificationType = getNormalEnum(NotificationTypeLiteral);

export type NotificationType = EnumValues<typeof NotificationType>;

export type InAppNotificationType = Exclude<
  NotificationType,
  | "MANUAL_ORG_CREATION"
  | "ACCOUNT_CREATION"
  | "DATA_CREDENTIAL_REQUEST"
  | "COMPUTE_SPEND_LIMIT"
>;

export const NotificationStatus = {
  READ: "READ",
  UNREAD: "UNREAD",
} as const;
export type NotificationStatus = EnumValues<typeof NotificationStatus>;

export const NotificationMethod = {
  EMAIL: "EMAIL",
  SLACK: "SLACK",
} as const;
export type NotificationMethod = EnumValues<typeof NotificationMethod>;

export const DataConnectionLinkType = {
  WORKSPACE: "WORKSPACE",
  PROJECT: "PROJECT",
} as const;
export type DataConnectionLinkType = EnumValues<typeof DataConnectionLinkType>;

export const DataConnectionRefreshSection = {
  DATABASES: "DATABASES",
  SCHEMAS: "SCHEMAS",
  TABLES: "TABLES",
  COLUMNS: "COLUMNS",
} as const;
export type DataConnectionRefreshSection = EnumValues<
  typeof DataConnectionRefreshSection
>;

export const MessageIntent = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
  NONE: "NONE",
} as const;
export type MessageIntent = EnumValues<typeof MessageIntent>;

export const ScheduleCadence = {
  HOURLY: "HOURLY",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  CUSTOM: "CUSTOM",
} as const;
export type ScheduleCadence = EnumValues<typeof ScheduleCadence>;

export const AllowedScheduleCadenceForExplorers: {
  [K in ScheduleCadence]: boolean;
} = {
  HOURLY: false,
  DAILY: true,
  WEEKLY: true,
  MONTHLY: true,
  CUSTOM: false,
};

export const RunTypeFilter = {
  SCHEDULED: "SCHEDULED",
  API: "API",
  ALL: "ALL",
} as const;
export type RunTypeFilter = EnumValues<typeof RunTypeFilter>;

// intentionally lowercase for backcompat
export const ExportType = {
  csv: "csv",
  parquet: "parquet",
  tablestore: "tablestore",
} as const;
export type ExportType = EnumValues<typeof ExportType>;

export const HexTypeLiteral = Union(
  Literal("PROJECT"),
  Literal("COMPONENT"),
  Literal("EXPLORE"),
);
export type HexType = Static<typeof HexTypeLiteral>;
export const HexType = getNormalEnum(HexTypeLiteral);

export const SearchHexesField = {
  TITLE: "TITLE",
  DESCRIPTION: "DESCRIPTION",
  SQL_CODE: "SQL_CODE",
  PY_CODE: "PY_CODE",
  R_CODE: "R_CODE",
  MARKDOWN_CODE: "MARKDOWN_CODE",
  TEXT_CELLS: "TEXT_CELLS",
} as const;
export type SearchHexesField = EnumValues<typeof SearchHexesField>;

/** `SearchHexesForHomeField`: This is an extended subset of the `SearchHexesField` which delineates fields by which we support text matching in our project list filters
 * These fields separate from `SearchHexesField` because are consumed by an endpoint that queries our postgres database, as opposed too our elasticsearch index,
 * and for now the two APIs do not support text matching for the same fields.
 */
export const SearchHexesForHomeField = {
  TITLE: SearchHexesField.TITLE,
  CREATOR: "CREATOR",
} as const;
export type SearchHexesForHomeField = EnumValues<
  typeof SearchHexesForHomeField
>;
export const SEARCH_HEXES_FOR_HOME_DEFAULT_FIELDS = [
  SearchHexesForHomeField.TITLE,
  SearchHexesForHomeField.CREATOR,
];
export const SEARCH_HEXES_FOR_HOME_FIELD_LABELS = {
  [SearchHexesForHomeField.TITLE]: "Title",
  [SearchHexesForHomeField.CREATOR]: "Creator",
};

export const CollectionOwnershipLevel = {
  OWN: "OWN",
  SHARED: "SHARED",
  ORG: "ORG",
  ALL: "ALL",
} as const;
export type CollectionOwnershipLevel = EnumValues<
  typeof CollectionOwnershipLevel
>;

export const CollectionOrder = {
  NAME: "NAME",
  RECENT_ADDITION: "RECENT_ADDITION",
} as const;
export type CollectionOrder = EnumValues<typeof CollectionOrder>;

export const KernelSourceImageVersionStateLiteral = Union(
  Literal("BUILDING"),
  Literal("READY"),
  Literal("PRE_BUILD_ERRORED"),
  Literal("ERRORED"),
);
export const KernelSourceImageVersionState = getNormalEnum(
  KernelSourceImageVersionStateLiteral,
);
export type KernelSourceImageVersionState = EnumValues<
  typeof KernelSourceImageVersionState
>;

export const KernelSourceImagePrewarmedLiteral = Union(Literal("FAST_LAUNCH"));
export const KernelSourceImagePrewarmed = getNormalEnum(
  KernelSourceImagePrewarmedLiteral,
);
export type KernelSourceImagePrewarmed = EnumValues<
  typeof KernelSourceImagePrewarmed
>;

export const KernelSourceImageStateLiteral = Union(
  Literal("REBUILDING"),
  Literal("PENDING"),
  Literal("BUILDING"),
  Literal("READY"),
  Literal("PRE_BUILD_ERRORED"),
  Literal("ERRORED"),
);
export const KernelSourceImageState = getNormalEnum(
  KernelSourceImageStateLiteral,
);
export type KernelSourceImageState = EnumValues<typeof KernelSourceImageState>;

export const KernelSourceTypeLiteral = Union(Literal("V0"), Literal("V1"));

export const KernelSourceImageType = getNormalEnum(KernelSourceTypeLiteral);
export type KernelSourceImageType = EnumValues<typeof KernelSourceImageType>;

export const KernelSourceImageVersionType = getNormalEnum(
  KernelSourceTypeLiteral,
);
export type KernelSourceImageVersionType = EnumValues<
  typeof KernelSourceImageVersionType
>;

export const S3ResourceType = {
  EXPORTS: "EXPORTS",
  FILES: "FILES",
  SCREENSHOTS: "SCREENSHOTS",
} as const;
export type S3ResourceType = EnumValues<typeof S3ResourceType>;

/**
 * Type of agent making a request.
 *
 * @see AGENT_TYPE_HEADER
 */
export const AgentType = {
  APP_SERVER: "APP_SERVER",
  DATA_SERVICE: "DATA_SERVICE",
  KERNEL: "KERNEL",
  SIDECAR: "SIDECAR",
  WEB_CLIENT: "WEB_CLIENT",
} as const;
export type AgentType = EnumValues<typeof AgentType>;

export const ClientType = {
  USER: "USER",
  API: "API",
  SERVER: "SERVER",
} as const;
export type ClientType = EnumValues<typeof ClientType>;

export enum ApiClientType {
  USER = "USER",
  APP_SESSION = "APP_SESSION",
  NOTION_PREVIEW = "NOTION_PREVIEW",
  SCREENSHOT_SERVICE = "SCREENSHOT_SERVICE",
  TABLE_PREVIEW = "TABLE_PREVIEW",
  WORKSPACE_TOKEN = "WORKSPACE_TOKEN",
  EMBEDDED_USER = "EMBEDDED_USER",
}

export const PromoTypeLiteral = Union(Literal("EXTENDED_TRIAL"));

export type PromoType = Static<typeof PromoTypeLiteral>;
export const PromoType = getNormalEnum(PromoTypeLiteral);

export const OAuthTokenAttribution = {
  CONNECTION: "CONNECTION",
  USER: "USER",
} as const;
export type OAuthTokenAttribution = EnumValues<typeof OAuthTokenAttribution>;

export const GoogleDriveOauthStatus = {
  SUCCESS: "SUCCESS",
  NO_REFRESH_TOKEN: "NOREFRESHTOKEN",
  USER_DECLINED: "USERDECLINED",
  WRONG_EMAIL: "WRONGEMAIL",
} as const;
export type GoogleDriveOauthStatus = EnumValues<typeof GoogleDriveOauthStatus>;

export const GoogleDriveFileTypeLiteral = Union(
  Literal("REGULAR"),
  Literal("GOOGLE_SHEET"),
  Literal("GOOGLE_DOC"),
);
export type GoogleDriveFileType = Static<typeof GoogleDriveFileTypeLiteral>;
export const GoogleDriveFileType = getNormalEnum(GoogleDriveFileTypeLiteral);

/**
 * Shared asset specific capabilties
 */
export const ExternalFileIntegrationCap = {
  /**
   * Can write files to the storage service
   */
  WRITE_BACK: "WRITE_BACK",
} as const;
export type ExternalFileIntegrationCap = EnumValues<
  typeof ExternalFileIntegrationCap
>;

export const CsvDelimiterLiteral = Union(Literal("TAB"));
export type CsvDelimiter = Static<typeof CsvDelimiterLiteral>;
export const CsvDelimiter = getNormalEnum(CsvDelimiterLiteral);

export const DataSourceDatabaseSaveStatus = {
  COMPLETE: "COMPLETE",
  INCOMPLETE: "INCOMPLETE",
} as const;

export type DataSourceDatabaseSaveStatus = EnumValues<
  typeof DataSourceDatabaseSaveStatus
>;

export const MagicUsageStatusLiteral = Union(
  Literal("INCLUDED"), // Magic will include usage of this data source
  Literal("EXCLUDED"), // Magic will not include usage of this data source
);
export type MagicUsageStatus = Static<typeof MagicUsageStatusLiteral>;
export const MagicUsageStatus = getNormalEnum(MagicUsageStatusLiteral);

export const DataSourcesLiteral = Union(
  Literal("DATA_CONNECTION"),
  Literal("DATABASE"),
  Literal("SCHEMA"),
  Literal("TABLE"),
  Literal("COLUMN"),
);

export type DataSources = Static<typeof DataSourcesLiteral>;
export const DataSources = getNormalEnum(DataSourcesLiteral);

export const dataSourceToDisplayText = (dataSource: DataSources): string => {
  switch (dataSource) {
    case DataSources.DATA_CONNECTION:
      return "data connection";
    case DataSources.DATABASE:
      return "database";
    case DataSources.SCHEMA:
      return "schema";
    case DataSources.TABLE:
      return "table";
    case DataSources.COLUMN:
      return "column";
    default:
      return assertNever(dataSource, dataSource);
  }
};

export const ViewDataOpenedFromLiteral = Union(
  Literal("PUBLISHED_APP_TOOLBAR"),
  Literal("FILTER_POPOVER"),
);
export type ViewDataOpenedFrom = Static<typeof ViewDataOpenedFromLiteral>;
export const ViewDataOpenedFrom = getNormalEnum(ViewDataOpenedFromLiteral);

export const DockerImageTagPullStatusLiteral = Union(
  Literal("IMAGE_PULL_FAILED"),
  Literal("IMAGE_PULL_SUCCEEDED"),
);
export type DockerImageTagPullStatus = Static<
  typeof DockerImageTagPullStatusLiteral
>;
export const DockerImageTagPullStatus = getNormalEnum(
  DockerImageTagPullStatusLiteral,
);

export const DataConnectionLanceStatusLiteral = Union(
  Literal("UP_TO_DATE"),
  Literal("STALE"),
  Literal("ERRORED"),
);

export type DataConnectionLanceStatus = Static<
  typeof DataConnectionLanceStatusLiteral
>;
export const DataConnectionLanceStatus = getNormalEnum(
  DataConnectionLanceStatusLiteral,
);

export const KernelDirectoryLiteral = Union(Literal("CELL_UPLOADS"));
export type KernelDirectory = Static<typeof KernelDirectoryLiteral>;
export const KernelDirectory = getNormalEnum(KernelDirectoryLiteral);

export const kernelDirectoryToName = (
  kernelDirectory: KernelDirectory,
): string => {
  switch (kernelDirectory) {
    case KernelDirectory.CELL_UPLOADS:
      return "cell_uploads";
    default:
      assertNever(kernelDirectory, kernelDirectory);
  }
};

export const FileTypeLiteral = Union(
  Literal("APP_SESSION_FILE"),
  Literal("FILE"),
);
export type FileType = Static<typeof FileTypeLiteral>;
export const FileType = getNormalEnum(FileTypeLiteral);

/**
 * Repesents the present status of a in progress review request
 */
export const ReviewRequestStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DENIED: "DENIED",
} as const;
export type ReviewRequestStatus = EnumValues<typeof ReviewRequestStatus>;

export function humanReadableReviewRequestStatus(
  status: ReviewRequestStatus,
  reset: boolean,
): string {
  if (reset) {
    return "re-review pending";
  }
  return {
    [ReviewRequestStatus.PENDING]: "review pending",
    [ReviewRequestStatus.APPROVED]: "approved",
    [ReviewRequestStatus.DENIED]: "changes requested",
  }[status];
}

/**
 * Repesents the status of a review request, updated once it is either closed
 * or published
 */
export const ReviewRequestResult = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  CANCELLED: "CANCELLED",
} as const;
export type ReviewRequestResult = EnumValues<typeof ReviewRequestResult>;

export function humanReadableReviewRequestResult(
  result: ReviewRequestResult,
): string {
  return {
    [ReviewRequestResult.PENDING]: "Review pending",
    [ReviewRequestResult.ACCEPTED]: "Published",
    [ReviewRequestResult.CANCELLED]: "Closed",
  }[result];
}

/**
 * Result of an individual review on a review request
 */
export const ReviewDeterminiation = {
  /**
   * Did not make a determination, just left a comment
   */
  COMMENTED: "COMMENTED",
  APPROVED: "APPROVED",
  DENIED: "DENIED",
} as const;
export type ReviewDeterminiation = EnumValues<typeof ReviewDeterminiation>;

export const DataBrowserTabLiteral = Union(
  Literal("DATA"),
  Literal("RECENTLY_USED"),
  Literal("FAVORITES"),
  Literal("SEMANTIC_LAYER"),
  Literal("PROJECT"),
  Literal("CURATION"),
);
export const DataBrowserTab = getNormalEnum(DataBrowserTabLiteral);
export type DataBrowserTab = Static<typeof DataBrowserTabLiteral>;

/**
 * Indicates the type of reviews being used for a project. Review systems are mutually
 * exclusive.
 */
export const ReviewSystem = {
  NONE: "NONE",
  LEGACY_GIT_SYNC: "LEGACY_GIT_SYNC",
  HEX: "HEX",
} as const;
export type ReviewSystem = EnumValues<typeof ReviewSystem>;

export const VcsPackageAuthorizationTypeLiteral = Union(
  Literal("NONE"),
  Literal("BEARER"),
  Literal("BASIC"),
);
export type VcsPackageAuthorizationType = Static<
  typeof VcsPackageAuthorizationTypeLiteral
>;
export const VcsPackageAuthorizationType = getNormalEnum(
  VcsPackageAuthorizationTypeLiteral,
);

export const ChartDisplayTypeLiteral = Union(
  Literal("CHART"),
  Literal("TABLE"),
);
export const ChartDisplayType = getNormalEnum(ChartDisplayTypeLiteral);
export type ChartDisplayType = Static<typeof ChartDisplayTypeLiteral>;

export const IconTypeLiteral = Union(
  Literal("chexagon"),
  Literal("certified"),
  Literal("trophy"),
  Literal("heart"),
  Literal("medal"),
  Literal("computerchip"),
  Literal("thumbsup"),
  Literal("blessed"),
  Literal("experiment"),
  Literal("lightbulb"),
  Literal("megaphone"),
  Literal("fire"),
  Literal("pluscircle"),
  Literal("checksquare"),
  Literal("notificationunread"),
  Literal("bug"),
  Literal("gem"),
  Literal("private"),
  Literal("unlock"),
  Literal("success"),
  Literal("reviewinprogress"),
  Literal("addreaction"),
  Literal("quickstart"),
  Literal("envelope"),
  Literal("key"),
  Literal("sparkles"),
  Literal("lightning"),
  Literal("star"),
  Literal("clock"),
  Literal("folder"),
);
export const IconType = getNormalEnum(IconTypeLiteral);
export type IconType = Static<typeof IconTypeLiteral>;

export const MagicTypeAheadProviderLiteral = Union(
  Literal("AUGMENT"),
  Literal("CODEIUM"),
  Literal("OFF"),
);
export type MagicTypeAheadProvider = Static<
  typeof MagicTypeAheadProviderLiteral
>;
export const MagicTypeAheadProvider = getNormalEnum(
  MagicTypeAheadProviderLiteral,
);

/**
 * These are the types of ways that kernel pods can be allocated.
 */
export const KernelAllocationKindTypeLiterals = Union(
  /**
   * These are kernel pods that were prewarmed and then claimed by a user.
   */
  Literal("CLAIMED_PREWARMED_KERNEL_POD"),
  /**
   * These are kernel pods that were created on demand when there are no prewarmed kernel pods available.
   */
  Literal("ON_DEMAND_KERNEL_POD"),
);
export const KernelAllocationKindType = getNormalEnum(
  KernelAllocationKindTypeLiterals,
);
export type KernelAllocationKindType = Static<
  typeof KernelAllocationKindTypeLiterals
>;

/**
 * These are the variants of AppSessions; see sessionTypeForAttribution() for more details.
 */
export const AppSessionAttributionTypeLiterals = Union(
  Literal("UNKNOWN"),
  Literal("LOGIC"),
  Literal("SCHEDULED"),
  Literal("API"),
  Literal("PUBLISH_PREVIEW"),
  Literal("PUBLISHED_APP"),
  Literal("PRESIGNED_EMBEDDED_APP"),
);
export const AppSessionAttributionType = getNormalEnum(
  AppSessionAttributionTypeLiterals,
);
export type AppSessionAttributionType = Static<
  typeof AppSessionAttributionTypeLiterals
>;

export const AppSessionVisibilityTypeLiterals = Union(
  Literal("VIEWER_ONLY"),
  Literal("PRIVATE_APP_VIEW"),
  Literal("UNSHARED_APP_VIEW"),
  Literal("SHARED_APP_VIEW"),
);
export const AppSessionVisibilityType = getNormalEnum(
  AppSessionVisibilityTypeLiterals,
);
export type AppSessionVisibilityType = Static<
  typeof AppSessionVisibilityTypeLiterals
>;

export const ScheduleNotificationConditionTypeLiterals = Union(
  Literal("METRIC_CELL"),
  Literal("TABLE_ROW_COUNT"),
);
export const ScheduleNotificationConditionType = getNormalEnum(
  ScheduleNotificationConditionTypeLiterals,
);
export type ScheduleNotificationConditionType = Static<
  typeof ScheduleNotificationConditionTypeLiterals
>;

export const ScheduleNotificationConditionPredicateLiterals = Union(
  Literal("GT"),
  Literal("GTE"),
  Literal("EQ"),
  Literal("NEQ"),
  Literal("LTE"),
  Literal("LT"),
  Literal("CONTAINS"),
  Literal("NOT_CONTAINS"),
);
export type ScheduleNotificationConditionPredicate = Static<
  typeof ScheduleNotificationConditionPredicateLiterals
>;
export const ScheduleNotificationConditionPredicate = getNormalEnum(
  ScheduleNotificationConditionPredicateLiterals,
);

export const RunNotificationTrigger = {
  API: "API",
  SCHEDULE: "SCHEDULE",
  TEST_SCHEDULE: "TEST_SCHEDULE",
} as const;
export type RunNotificationTrigger = EnumValues<typeof RunNotificationTrigger>;

export const RunNotificationRunStatus = {
  FAILED: "FAILED",
  SUCCEEDED: "SUCCEEDED",
  TIMED_OUT: "TIMED_OUT",
} as const;
export type RunNotificationRunStatus = EnumValues<
  typeof RunNotificationRunStatus
>;

export enum EntityDiffType {
  COLUMN = "COLUMN",
  TABLE = "TABLE",
  SCHEMA = "SCHEMA",
  DATABASE = "DATABASE",
}

export enum EntityGrantDiffType {
  DATABASE_GRANT = "DATABASE_GRANT",
  SCHEMA_GRANT = "SCHEMA_GRANT",
  TABLE_GRANT = "TABLE_GRANT",
}

export const CollapsibleCellLabelStyle = {
  AUTO: "AUTO",
  H1: "H1",
  H2: "H2",
  H3: "H3",
  P: "P",
} as const;
export type CollapsibleCellLabelStyle = EnumValues<
  typeof CollapsibleCellLabelStyle
>;
