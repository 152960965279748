import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import { CellReferencesParseError, CellReferencesV2 } from "../../../code";
import { TextCellId } from "../../../idTypeBrands";
import { RichTextDocument } from "../../../richTextTypes";
import { makeUpdateCellContentsOperation } from "../makeUpdateCellContentsOperation";

export interface TextCellUpdatableFields {
  richText: RichTextDocument;
  cellReferencesV2: CellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
}

const _UpdateTextCellSafeFields: {
  [key in keyof TextCellUpdatableFields]: "";
} = {
  richText: "",
  cellReferencesV2: "",
  cellReferencesParseError: "",
};
export const UpdateTextCellSafeFields = Object.keys(_UpdateTextCellSafeFields);

export const UPDATE_TEXT_CELL = makeUpdateCellContentsOperation<
  TextCellUpdatableFields,
  TextCellId
>()({
  type: "UPDATE_TEXT_CELL",
  entityName: "TextCell",
  fieldName: "textCellId",
});
export type UPDATE_TEXT_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_TEXT_CELL
>;
