import { MapInitialView } from "../../../map/mapTypes";

// initial view is San Francisco
export const MAP_INITIAL_VIEW: MapInitialView = {
  longitude: -122.41669,
  latitude: 37.7853,
  zoom: 13,
  pitch: 0,
  bearing: 0,
};

export const MAP_DEFAULT_HEIGHT = 520;
export const MAP_LOCALHOST_ASSET_PATH = "https://static.hex.site/";
