import { Boolean, Literal, Record as RRecord, Static, Union } from "runtypes";

import { ProjectLanguage } from "./enums";
import { assertNever } from "./errors";
import { AppSessionId } from "./idTypeBrands";
import { KernelSize } from "./kernelConfigTypes.js";
import { KernelImage } from "./typeBrands";
import { uuid } from "./uuid";

export const KernelAppLabelLiteral = Union(
  Literal("hex-python-kernel"),
  Literal("hex-r-kernel"),
);
export type KernelAppLabelLiteral = Static<typeof KernelAppLabelLiteral>;

export const getKernelName = ({
  language,
  prefix,
}: {
  language: ProjectLanguage;
  prefix: string;
}): string => {
  switch (language) {
    case ProjectLanguage.R:
      return `${prefix}-r-kernel`;
    case ProjectLanguage.PYTHON:
      return `${prefix}-python-kernel`;
    default:
      assertNever(language, language);
  }
};

export const getKernelApp = ({
  language,
}: {
  language: ProjectLanguage;
}): KernelAppLabelLiteral =>
  KernelAppLabelLiteral.check(getKernelName({ language, prefix: "hex" }));

export const getFullKernelName = ({
  kernelImage,
  kernelSize,
  language,
  prefix,
}: {
  language: ProjectLanguage;
  prefix: string;
  kernelImage: KernelImage;
  kernelSize: KernelSize;
}): string => {
  const suffix = uuid().replace(/-/gi, "");
  return `${getKernelName({
    language,
    prefix,
  })}-${kernelImage}-${kernelSize}-${suffix}`;
};

export const getKernelGenerateName = ({
  kernelImage,
  kernelSize,
  language,
  prefix,
}: {
  language: ProjectLanguage;
  prefix: string;
  kernelImage: KernelImage;
  kernelSize: KernelSize;
}): string => {
  return `${getKernelName({
    language,
    prefix,
  })}-${kernelImage}-${kernelSize}-`;
};

export function toLowercase<T extends string>(str: T): Lowercase<T> {
  return str.toLowerCase() as Lowercase<T>;
}

export function toUpperCase<T extends string>(str: T): Uppercase<T> {
  return str.toUpperCase() as Uppercase<T>;
}

export function createPodHostnameAndSubdomain({
  appSessionId,
  releaseName,
}: {
  appSessionId: AppSessionId;
  releaseName: string;
}): { hostname: string; subdomain: string } {
  return {
    hostname: `hex-kernel-for-${appSessionId}`,
    subdomain: `${releaseName}-kernel`,
  };
}

export const KernelManagementCPMigrationState = RRecord({
  compareBackup: Boolean,
  primary: Union(Literal("K8s"), Literal("CP")),
});

export type KernelManagementCPMigrationState = Static<
  typeof KernelManagementCPMigrationState
>;

export const DefaultKernelManagementCPMigrationState: KernelManagementCPMigrationState =
  { primary: "CP", compareBackup: false };
