import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations";
import {
  DataConnectionHexVersionLinkId,
  DataConnectionId,
} from "../../idTypeBrands";
import { SemanticCap } from "../../semanticCaps";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

const CREATE_DATA_CONNECTION_HEX_VERSION_LINK_TYPE =
  "CREATE_DATA_CONNECTION_HEX_VERSION_LINK" as const;
export const CREATE_DATA_CONNECTION_HEX_VERSION_LINK =
  createHexVersionAtomicOperationDefinition({
    type: CREATE_DATA_CONNECTION_HEX_VERSION_LINK_TYPE,
    readAuth: {
      kind: "hasSemanticCap",
      cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCap",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
        },
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.IMPORT_SHARED_ASSET,
          idArg: "dataConnectionId",
          idType: "DataConnection",
        },
        {
          kind: "idArgDoesNotExist",
          idArg: "dataConnectionHexVersionLinkId",
          idType: "DataConnectionHexVersionLink",
        },
      ],
    },
    logSafe: ["dataConnectionHexVersionLinkId", "dataConnectionId"],
    conflictId: (op) =>
      `${CREATE_DATA_CONNECTION_HEX_VERSION_LINK_TYPE}-${op.payload.dataConnectionHexVersionLinkId}`,
    create: ({
      dataConnectionHexVersionLinkId,
      dataConnectionId,
    }: {
      dataConnectionHexVersionLinkId: DataConnectionHexVersionLinkId;
      dataConnectionId: DataConnectionId;
    }) => ({
      type: CREATE_DATA_CONNECTION_HEX_VERSION_LINK_TYPE,
      payload: {
        dataConnectionHexVersionLinkId,
        dataConnectionId,
      },
    }),
  });

export type CREATE_DATA_CONNECTION_HEX_VERSION_LINK =
  ExtractAtomicOperationFromDefinition<
    typeof CREATE_DATA_CONNECTION_HEX_VERSION_LINK
  >;
