import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import {
  CellReferencesParseError,
  CellReferencesV2,
  StoredCellReferencesV3,
  StoredJinjaSqlReferences,
  StoredSqlReferencesV3,
} from "../../../code";
import { DATA_FRAME_SQL_CONNECTION_ID } from "../../../constants";
import { CellOutputType } from "../../../enums";
import { DataConnectionId, SqlCellId } from "../../../idTypeBrands";
import { makeUpdateCellContentsOperation } from "../makeUpdateCellContentsOperation";

export interface SqlCellUpdatableFields {
  source: string;
  /**
   * perms on this are manually checked server side
   */
  connectionId: DataConnectionId | DATA_FRAME_SQL_CONNECTION_ID | null;
  resultVariable: string;
  useRichDisplay: boolean;
  loadIntoDataFrame: boolean;
  cellReferencesV2: CellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
  sqlCellReferencesV3: StoredSqlReferencesV3 | null;
  jinjaCellReferencesV3: StoredCellReferencesV3 | null;
  jinjaSqlReferences: StoredJinjaSqlReferences | null;
  castDecimals: boolean;
  useNativeDates: boolean;
  sqlCellOutputType: CellOutputType;
}

const _UpdateSqlCellSafeFields: {
  [key in keyof SqlCellUpdatableFields]: "";
} = {
  source: "",
  connectionId: "",
  resultVariable: "",
  useRichDisplay: "",
  loadIntoDataFrame: "",
  cellReferencesV2: "",
  cellReferencesParseError: "",
  sqlCellReferencesV3: "",
  jinjaCellReferencesV3: "",
  jinjaSqlReferences: "",
  castDecimals: "",
  useNativeDates: "",
  sqlCellOutputType: "",
};
export const UpdateSqlCellSafeFields = Object.keys(_UpdateSqlCellSafeFields);

export const UPDATE_SQL_CELL = makeUpdateCellContentsOperation<
  SqlCellUpdatableFields,
  SqlCellId,
  {
    /**
     * Optional boolean to override existing logic for running the cell after
     * updating the sql cell. This is currently only implemented for field
     * `source` and will be a no-op for other fields.
     */
    runCell?: boolean;
  }
>()({
  type: "UPDATE_SQL_CELL",
  entityName: "SqlCell",
  fieldName: "sqlCellId",
});
export type UPDATE_SQL_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_SQL_CELL
>;
