// http://emailregex.com/
export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const cleanEmail = (email: string): string => {
  return email.trim().toLowerCase();
};

/**
 * Cleans and validates an email to check if it is valid
 * @param origEmail the email to clean and validate
 */
export const validateEmail = (origEmail: string): string => {
  const email = cleanEmail(origEmail);
  if (!EMAIL_REGEX.test(email)) {
    throw new Error("Not a valid email");
  }
  return email;
};

/**
 * Remove portion of email alias including and after the "+" up until the "@".
 */
export const stripPlusComponent = (email: string): string =>
  email.replace(/\+.+@/, "@");

export const SUPPORT_USER_EMAIL = "support@hex.tech";
