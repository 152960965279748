import { DisplayTableColumnOutputType } from "../display-table/outputTypes.js";
import { assertNever } from "../errors.js";
import { HqlNextColumn } from "../explore/types.js";
import { SemanticDatasetName } from "../idTypeBrands.js";
import {
  DataType,
  Dataset,
  Dimension,
} from "../semantic-layer/generated/hex_sl_schema.js";
import { QUOTING_CHARACTERS_REGEX } from "../sql/dialects.js";

import { CalciteType, columnTypeToCalciteType } from "./types.js";

export function getGeneratedDimension(
  field: Pick<HqlNextColumn, "value" | "calc"> & {
    dataType: CalciteType | undefined;
  },
  /**
   * For non dataframe mode cases where we query the warehouse directly - we
   * can't guarantee that the column is actually a string type, so we specify
   * that these columns should be explicitly casted. This is a bit heavy handed
   * but ensures that queries won't fail due to incorrect types.
   */
  castStringDimensions: boolean,
): Dimension {
  return {
    name: field.value,
    ...(field.dataType
      ? { type: calciteTypeToHqlNextType(field.dataType) }
      : {}),
    ...(field.calc ? { calc: field.calc } : {}),
    ...(field.dataType === "VARCHAR" && castStringDimensions
      ? { cast: true }
      : {}),
  };
}

export function tablePathToDatasetName(tablePath: string): SemanticDatasetName {
  // TODO(explore-hql-next): TEMPORARY - eventually we should include the
  // quoting charaters, but for now hex-sl wraps everything in double quotes so
  // we strip them for now. This will not work for dialects with non double quote quoting characters
  const cleanedSqlTable = tablePath.replaceAll(QUOTING_CHARACTERS_REGEX, "");

  // we need to replace "." because those are used to delimit datasets in a
  // join path
  return cleanedSqlTable.replaceAll(".", "_") as SemanticDatasetName;
}

interface GeneratedDatasetOptions {
  primaryKey?: {
    name: string;
    type: DataType;
  };
}

/**
 * This created a generated semantic dataset for the non semantic case (when
 * exploring a warehouse table or dataframe.)
 */
export function getGeneratedDataset(
  sqlTable: string,
  opts: GeneratedDatasetOptions = {},
): Dataset {
  const result: Dataset = {
    name: tablePathToDatasetName(sqlTable),
    sql_table: sqlTable,
    dimensions: [],
    measures: [],
  };

  if (opts.primaryKey != null) {
    result.dimensions ??= [];
    result.dimensions.push({
      name: opts.primaryKey.name,
      type: opts.primaryKey.type,
      column: opts.primaryKey.name,
      primary_key: true,
    });
  }

  return result;
}

export function columnTypeToHqlNextType(
  columnType: DisplayTableColumnOutputType,
): DataType {
  return calciteTypeToHqlNextType(columnTypeToCalciteType(columnType));
}

export function calciteTypeToHqlNextType(calciteType: CalciteType): DataType {
  switch (calciteType) {
    case "VARCHAR":
      return "string";
    case "INTEGER":
    case "DOUBLE":
    case "BIGINT":
    case "FLOAT":
    case "DECIMAL":
      return "number";
    case "TIMESTAMP":
      return "timestamp";
    case "TIMESTAMPTZ":
      return "timestamptz";
    case "BOOLEAN":
      return "boolean";
    case "DATE":
      return "date";
    default:
      assertNever(calciteType, calciteType);
  }
}

export function hqlNextTypeToColumnType(
  hqlType: DataType,
): DisplayTableColumnOutputType {
  switch (hqlType) {
    case "string":
      return "STRING";
    case "number":
      return "NUMBER";
    case "timestamp":
      return "DATETIME";
    case "timestamptz":
      return "DATETIMETZ";
    case "time":
      return "TIME";
    case "boolean":
      return "BOOLEAN";
    case "date":
      return "DATE";
    case "other":
    case "null":
      return "UNKNOWN";
    default:
      assertNever(hqlType, hqlType);
  }
}

/**
 * Gets the dataset path for a field - if queryPath is null then the field is
 * just on the base dataset.
 */
export const getDatasetPathForField = (
  field: HqlNextColumn,
): string | undefined => {
  return field.queryPath && field.queryPath.length > 0
    ? joinDatasetQueryPath(field.queryPath)
    : undefined;
};

export const getDatasetNameFromPath = (path: string): string | undefined => {
  return path.split(":").pop() ?? undefined;
};

export function joinDatasetQueryPath(queryPath: string[]): string {
  return queryPath.join(":");
}
