import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations";
import {
  BlockCellId,
  CellId,
  ComponentImportCellId,
  MagicEventId,
} from "../../idTypeBrands";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

import { CREATE_CELL } from "./createCellOperation";

export interface CellUpdatableFields {
  label: string | null;
  parentComponentImportCellId: ComponentImportCellId | null;
  parentBlockCellId: BlockCellId | null;
  latestMagicEventId: MagicEventId | null;
  /** @deprecated @see MOVE_CELL instead  */
  parentCellId: CellId | null;
}

const _UpdateCellSafeFields: {
  [key in keyof CellUpdatableFields]: "";
} = {
  label: "",
  parentComponentImportCellId: "",
  parentBlockCellId: "",
  latestMagicEventId: "",
  parentCellId: "",
};
export const UpdateCellSafeFields = Object.keys(_UpdateCellSafeFields);

const UPDATE_CELL_TYPE = "UPDATE_CELL" as const;
export const UPDATE_CELL = createHexVersionAtomicOperationDefinition({
  type: UPDATE_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_PROJECT_CONTENTS,
    idArg: "cellId",
    idType: "Cell",
  },
  logSafe: ["cellId", "key"],
  conflictId: (op) =>
    `${UPDATE_CELL_TYPE}-${op.payload.cellId}-${op.payload.key}`,
  creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
  excludeFromHistory: (op) => op.payload.key === "parentBlockCellId",
  create: <K extends keyof CellUpdatableFields>(
    cellId: CellId,
    key: K,
    value: CellUpdatableFields[K],
  ) => ({
    type: UPDATE_CELL_TYPE,
    payload: {
      cellId,
      key,
      value,
    },
  }),
});

export type UPDATE_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_CELL
>;
