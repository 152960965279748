/* eslint-disable tree-shaking/no-side-effects-in-initialization */

import { range } from "lodash";

import { assertIsDefined } from "./assertions";

/**
 * Fractional indexing is a technique that allows for eventual consistency for an ordered
 * collection without needing to do complex conflict resolution like with OT.
 * The basic idea is that when you insert an object, its index is effectively the midpoint
 * of the two indices it was inserted between. For example, if inserting between `1` and `2`, the
 * index of the new object could be `1.5`.
 *
 * Since a fractional index is unbounded in length, we use strings to represent them and rely on
 * lexographic sorting to avoid the issues of deserializing them into javascript. We use base 95
 * to reduce the space each index requires. The examples and tests stick to base 10.
 */

export const BASE_95_DIGITS =
  " !\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~";

export const BASE_10_DIGITS = "0123456789";

// can't use locale compare with fractional index strings
// since it sorts alphabetically instead of using ascii
export function asciiCompare(a: string, b: string): number {
  if (a === b) {
    return 0;
  } else if (a > b) {
    return 1;
  } else {
    return -1;
  }
}

/**
 * Each fractional index has two parts, the integer part and the fractional part. The integer part has a fixed
 * width of 10 digits, and the fractional part is variable length. Example:
 *
 *          int            frac
 *  _________|_________ ____|______
 * |                   |           |
 *  0 0 0 0 0 4 0 1 4 5 2 7 9 6 7 2
 *
 * This base 10 example is equal to the float `40145.279672`.
 *
 * The reason for also supporting an integer part is that it allows us to easily append or prepend
 * without needing to extend the length of the fractional part. It also makes it very easy to migrate
 * from existing traditional indices. The integer part is fixed width so that it supports lexographic
 * sorting.
 */

const INTEGER_PART_LENGTH = 10;

function getIntegerPart(index: string): string {
  return index.slice(0, INTEGER_PART_LENGTH);
}

function getFractionalPart(index: string): string {
  return index.slice(INTEGER_PART_LENGTH);
}

export function addIntegers(a: string, b: string, digits: string): string {
  let newInteger = "";

  if (a.length < INTEGER_PART_LENGTH || b.length < INTEGER_PART_LENGTH) {
    throw new Error("string integers were not of minimum length");
  }

  // add the digits one by one, keeping track of the carry
  let carry = 0;
  for (let i = INTEGER_PART_LENGTH - 1; i >= 0; i--) {
    const aDigit = a[i];
    const bDigit = b[i];
    assertIsDefined(aDigit);
    assertIsDefined(bDigit);
    const aDigitIndex = digits.indexOf(aDigit);
    const bDigitIndex = digits.indexOf(bDigit);

    const potentialNextDigitIndex = aDigitIndex + bDigitIndex + carry;
    carry = potentialNextDigitIndex > digits.length - 1 ? 1 : 0;

    const nextDigitIndex = potentialNextDigitIndex % digits.length;

    newInteger = digits[nextDigitIndex] + newInteger;
  }

  if (carry === 1) {
    throw new Error("overflow, maximum integer part exceeded");
  }

  return newInteger;
}

export function subtractIntegers(a: string, b: string, digits: string): string {
  let newInteger = "";

  if (a.length < INTEGER_PART_LENGTH || b.length < INTEGER_PART_LENGTH) {
    throw new Error("string integers were not of minimum length");
  }

  // subtract the digits one by one, keeping track of the borrow
  let borrow = 0;
  for (let i = INTEGER_PART_LENGTH - 1; i >= 0; i--) {
    const aDigit = a[i];
    const bDigit = b[i];
    assertIsDefined(aDigit);
    assertIsDefined(bDigit);
    const aDigitIndex = digits.indexOf(aDigit);
    const bDigitIndex = digits.indexOf(bDigit);

    const potentialNextDigitIndex = aDigitIndex - bDigitIndex - borrow;
    borrow = potentialNextDigitIndex < 0 ? 1 : 0;

    const nextDigitIndex =
      potentialNextDigitIndex < 0
        ? digits.length + potentialNextDigitIndex
        : potentialNextDigitIndex;

    newInteger = digits[nextDigitIndex] + newInteger;
  }

  if (borrow === 1) {
    throw new Error("overflow, minimum integer part subceeded");
  }

  return newInteger;
}

/**
 * For the fractional part zero is represented by the empty string `""` and the largest
 * value by a special sentinel.
 *
 * We don't include trailing zeros since they would cause `.3` to be distinct from `.30`
 *
 * We don't find the exact midpoint, instead we optimize on getting the shortest midpoint
 * and code clarity
 */

export const FRACTIONAL_PART_START = "";
export const FRACTIONAL_PART_END = {
  FRACTIONAL_PART_END: "FRACTIONAL_PART_END",
} as const;
const isFracStart = (x: unknown): x is typeof FRACTIONAL_PART_START =>
  x === FRACTIONAL_PART_START;
const isFracEnd = (x: unknown): x is typeof FRACTIONAL_PART_END =>
  x === FRACTIONAL_PART_END;

// a must be less than b
export function fractionalPartMidpoint(
  a: string,
  b: string | typeof FRACTIONAL_PART_END,
  digits: string,
): string {
  if (!isFracEnd(b) && a >= b) {
    throw new Error(`${a} is not less than ${b}`);
  }

  if (!isFracEnd(b)) {
    // search for the longest common prefix of a and b.
    // we need to pad a with zeros as a part of this search
    // in case of something like this, `.3` and `.30004` should have the common prefix `.3000`
    let n = 0;
    while ((a[n] || digits[0]) === b[n]) {
      n++;
    }
    // if we can find a non zeero length common prefix, we can just find the midpoint
    // of the remaining parts of a and b, using the above example `.3000 + midpoint("", "4")`
    if (n > 0) {
      const aRemainder = a.slice(n);
      const bRemainder = b.slice(n);
      return (
        b.slice(0, n) + fractionalPartMidpoint(aRemainder, bRemainder, digits)
      );
    }
  }

  if (
    (!isFracStart(a) && a.length === 0) ||
    (!isFracEnd(b) && b.length === 0)
  ) {
    throw new Error("input strings were not of valid length");
  }

  // if a and b have different starting digits and they are not consecutive
  // we can just find the mid point between the leading digits
  const aDigit = isFracStart(a) ? a : a[0];
  const bDigit = isFracEnd(b) ? b : b[0];
  assertIsDefined(aDigit);
  assertIsDefined(bDigit);

  // if a is the start we make its leading digit 0
  const aDigitPosition =
    a !== FRACTIONAL_PART_START ? digits.indexOf(aDigit) : 0;

  // if b is the end we make its leading digit a "fake" one of thats larger than the rest
  const bDigitPosition = !isFracEnd(bDigit)
    ? digits.indexOf(bDigit)
    : digits.length;

  // not consecutive
  if (bDigitPosition - aDigitPosition > 1) {
    const midDigitPosition = Math.floor((aDigitPosition + bDigitPosition) / 2);
    const newDigit = digits[midDigitPosition];
    assertIsDefined(newDigit);
    return newDigit;
  }

  // if we reach this case, we know the leading digit of b is
  // exactly 1 higher than the leading digit of a.
  // if b is longer than 1 digit, we can simply truncate b
  // to get a midpoint. The advantage being we don't increase the
  // length of the string
  if (!isFracEnd(b) && b.length > 1) {
    return b.slice(0, 1);
  }

  // the final case we can know the leading digit of b
  // immediatly follows the leading digit of a and is of length 1,
  // so we so can just find the midpoint between the
  // remainder of a and END
  return (
    digits[aDigitPosition] +
    fractionalPartMidpoint(a.slice(1), FRACTIONAL_PART_END, digits)
  );
}

/**
 * putting it all together
 */
export const FRACTIONAL_INDEX_START = {
  FRACTIONAL_INDEX_START: "FRACTIONAL_INDEX_START",
} as const;
const isStart = (x: unknown): x is typeof FRACTIONAL_INDEX_START =>
  x === FRACTIONAL_INDEX_START;

export const FRACTIONAL_INDEX_END = {
  FRACTIONAL_INDEX_END: "FRACTIONAL_INDEX_END",
} as const;
const isEnd = (x: unknown): x is typeof FRACTIONAL_INDEX_END =>
  x === FRACTIONAL_INDEX_END;

// a must be less than b
export function fractionalIndexMidpoint({
  a,
  b,
  buffer,
  digits,
}: {
  a: string | typeof FRACTIONAL_INDEX_START;
  b: string | typeof FRACTIONAL_INDEX_END;
  // how much to increment / decrement the integer part by
  // when appending / prepending. skipping several integers in this case
  // allows us to keep most inserts as just inegers, keeping the indices constant
  // in space
  buffer: string;
  digits: string;
}): string {
  const digit0 = digits[0];
  const digit1 = digits[1];
  const lastDigit = digits[digits.length - 1];
  assertIsDefined(digit0);
  assertIsDefined(digit1);
  assertIsDefined(lastDigit);
  const integerZero = digit0.repeat(INTEGER_PART_LENGTH);
  const integerOne = digit1.padStart(INTEGER_PART_LENGTH, digit0);
  const integerMax = lastDigit.repeat(INTEGER_PART_LENGTH);

  const integerOnePlusBuffer = addIntegers(integerOne, buffer, digits);
  const integerMaxMinusBuffer = subtractIntegers(integerMax, buffer, digits);

  if (!isStart(a) && !isEnd(b) && a >= b) {
    throw new Error(`${a} is not less than ${b}`);
  } else if (isStart(a) && isEnd(b)) {
    const middleDigit = digits[Math.floor(digits.length / 2)];
    assertIsDefined(middleDigit);
    // this is not exactly middle, but its close enough
    return middleDigit.concat(digit0.repeat(INTEGER_PART_LENGTH - 1));

    // If a is START, return the floor of b
    // or b does not have a fractional part, decrement the integer part of b
  } else if (isStart(a) && !isEnd(b)) {
    const bIntegerPart = getIntegerPart(b);
    const bFractionalPart = getFractionalPart(b);

    // special case: if the integer part of b is zero
    // we cannot decrement it so we must find
    // the midpoint between the fractional part of b
    // and fractional START
    if (bIntegerPart === integerZero) {
      if (bFractionalPart === FRACTIONAL_PART_START) {
        throw new Error(
          "Can't find a midpoint between true zero and fractional index START. Both the integer and fractional part of b are zero.",
        );
      } else {
        return (
          integerZero +
          fractionalPartMidpoint(FRACTIONAL_PART_START, bFractionalPart, digits)
        );
      }
    }

    // otherwise see if we can just use the floor
    if (bIntegerPart < b) {
      return bIntegerPart;
    }

    // check if subtracting buffer would cause overflow
    // we use one for bound checks since you can't find
    // a fractional part midpoint between 0 and 0
    if (bIntegerPart > integerOnePlusBuffer) {
      return subtractIntegers(bIntegerPart, buffer, digits);
      // check if subtracting one would cause overflow
    } else if (bIntegerPart !== integerOne) {
      return subtractIntegers(bIntegerPart, integerOne, digits);
      // otherwise just find the fractional index midpoint with 0
    } else {
      return fractionalIndexMidpoint({ a: integerZero, b, buffer, digits });
    }
    // If b is END, increment the integer part of a
    // and truncate the fractional part
  } else if (!isStart(a) && isEnd(b)) {
    const aIntegerPart = getIntegerPart(a);
    // check if adding buffer would cause overflow
    if (aIntegerPart < integerMaxMinusBuffer) {
      return addIntegers(aIntegerPart, buffer, digits);
      // check if adding one would cause overflow
    } else if (aIntegerPart !== integerMax) {
      return addIntegers(aIntegerPart, integerOne, digits);
      // otherwise just find the fractional midpoint with end
    } else {
      return (
        aIntegerPart +
        fractionalPartMidpoint(
          getFractionalPart(a),
          FRACTIONAL_PART_END,
          digits,
        )
      );
    }
  } else if (!isStart(a) && !isEnd(b)) {
    const aIntegerPart = getIntegerPart(a);
    const aFractionalPart = getFractionalPart(a);
    const bIntegerPart = getIntegerPart(b);
    const bFractionalPart = getFractionalPart(b);

    // if the integer parts are equal, just find the fractional midpoint
    if (aIntegerPart === bIntegerPart) {
      return (
        aIntegerPart +
        fractionalPartMidpoint(aFractionalPart, bFractionalPart, digits)
      );
    }

    // otherwise see if we can get away with just incrementing the integer part of a.
    // TODO: take a integer midpoint instead to create more space between indices
    // https://github.com/hex-inc/hex/issues/1632
    const newInteger = addIntegers(aIntegerPart, integerOne, digits);
    if (newInteger < b) {
      return newInteger;
    }

    // if aIntegerPart + 1 === bIntegerPart,
    // find the mid point between a and the next integer (bIntegerPart)
    return (
      aIntegerPart +
      fractionalPartMidpoint(aFractionalPart, FRACTIONAL_PART_END, digits)
    );
  }

  // this should never happen, but TS typegaurding isn't
  // smart enough to catch this fact
  throw new Error(`${a} and ${b} not valid parameters`);
}

export const BASE_95_ONE_BILLION = "     ,:A>k";

export const fractionalIndexMidpoint95 = (
  a: string | typeof FRACTIONAL_INDEX_START,
  b: string | typeof FRACTIONAL_INDEX_END,
): string =>
  fractionalIndexMidpoint({
    a,
    b,
    buffer: BASE_95_ONE_BILLION,
    digits: BASE_95_DIGITS,
  });

export function getIndicesBetween({
  count,
  end = FRACTIONAL_INDEX_END,
  start = FRACTIONAL_INDEX_START,
}: {
  count: number;
  start?: string | typeof FRACTIONAL_INDEX_START;
  end?: string | typeof FRACTIONAL_INDEX_END;
}): string[] {
  let previous = start;
  return range(count).map(() => {
    const next = fractionalIndexMidpoint95(previous, end);
    previous = next;
    return next;
  });
}

export const initialMidpoint95 = fractionalIndexMidpoint95(
  FRACTIONAL_INDEX_START,
  FRACTIONAL_INDEX_END,
);

// this should ONLY be used for debug purposes since it is not
// exact. also this is most not likely not implemented in an optimal way
// so may be slow / prone to overflow
export function fractionalIndexToNumber(x: string, digits: string): number {
  const integerPart = getIntegerPart(x);
  const fractionalPart = getFractionalPart(x);

  let accumulator = 0;

  [...integerPart].reverse().forEach((c, i) => {
    accumulator += digits.indexOf(c) * Math.pow(digits.length, i);
  });

  [...fractionalPart].forEach((c, i) => {
    accumulator += digits.indexOf(c) * Math.pow(digits.length, -i - 1);
  });

  return accumulator;
}

export const fractionalIndexToNumber95 = (x: string): number =>
  fractionalIndexToNumber(x, BASE_95_DIGITS);
