import { uniqBy } from "lodash";

import { assertNever } from "../errors.js";
import {
  CalciteType,
  HqlAggregationFunction,
  HqlTruncUnit,
  calciteTypeToColumnType,
  columnTypeToCalciteType,
} from "../hql/types.js";
import {
  PivotAggregation,
  PivotFieldInputType,
  PivotGroupByField,
  PivotTableConfig,
  PivotTruncateDateInterval,
  PivotValueField,
} from "../pivot/pivotTypes.js";

import { defaultExploreField, defaultExploreSpec } from "./exploreDefaults.js";
import {
  EXPLORE_CHART_ONLY_AGGREGATIONS,
  generateColumnIdForField,
  isAggregatedField,
} from "./exploreFieldUtils.js";
import {
  ExploreField,
  ExploreFieldType,
  ExploreSeriesId,
  ExploreSpec,
} from "./types.js";

export function mapExploreFieldsToPivotFieldsObject(fields: ExploreField[]): {
  rows: ExploreField[];
  columns: ExploreField[];
  values: ExploreField[];
} {
  const rows: ExploreField[] = [];
  const columns: ExploreField[] = [];
  const values: ExploreField[] = [];

  fields
    // handle pivot fields first
    .filter((field) => {
      if (field.channel == null) {
        return false;
      } else if (field.channel === "row") {
        rows.push(field);
        return false;
      } else if (field.channel === "column") {
        columns.push(field);
        return false;
      } else if (field.channel === "value") {
        values.push(field);
        return false;
      }
      return true;
    })
    // map remaining fields into relevant sockets
    .forEach((field) => {
      if (isAggregatedField(field)) {
        let updatedField = field;
        if (
          field.aggregation &&
          EXPLORE_CHART_ONLY_AGGREGATIONS.has(field.aggregation)
        ) {
          updatedField = { ...field, aggregation: "Count" };
        }
        values.push(updatedField);
      } else {
        rows.push(field);
      }
    });

  return {
    rows: rows.map((row) => ({ ...row, channel: "row" as const })),
    columns: columns.map((column) => ({
      ...column,
      channel: "column" as const,
    })),
    values: values.map((value) => ({ ...value, channel: "value" as const })),
  };
}

export function mapExploreFieldsToPivotFields(
  fields: ExploreField[],
): ExploreField[] {
  const { columns, rows, values } = mapExploreFieldsToPivotFieldsObject(fields);
  return [...rows, ...columns, ...values];
}

export function exploreFieldsToPivotConfig(
  fields: ExploreField[],
): PivotTableConfig {
  const pivotConfig: PivotTableConfig = { rows: [], columns: [], values: [] };

  uniqBy(
    fields,
    (field) =>
      `${generateColumnIdForField(field)}//${field.channel}//${field.aggregation ?? "no-agg"}//${field.truncUnit ?? "no-trunc"}`,
  ).forEach((field) => {
    if (field.channel === "row") {
      pivotConfig.rows.push(exploreFieldToPivotGroupByField(field));
    } else if (field.channel === "column") {
      pivotConfig.columns.push(exploreFieldToPivotGroupByField(field));
    } else if (field.channel === "value") {
      pivotConfig.values.push(exploreFieldToPivotValueField(field));
    }
  });

  return pivotConfig;
}

export function pivotConfigToExploreSpec(
  pivotConfig: PivotTableConfig,
): ExploreSpec {
  const exploreSpec = defaultExploreSpec();

  if (exploreSpec.chartConfig.series[0] == null) {
    // as long as nothing changes within `defaultExploreSpec` this will always
    // be defined
    throw new Error("Expected chart series to be defined");
  }
  const seriesId = exploreSpec.chartConfig.series[0].id;

  pivotConfig.columns.forEach((column) => {
    exploreSpec.fields.push(
      pivotGroupByFieldToExploreField(column, seriesId, "column"),
    );
  });

  pivotConfig.rows.forEach((row) => {
    exploreSpec.fields.push(
      pivotGroupByFieldToExploreField(row, seriesId, "row"),
    );
  });

  pivotConfig.values.forEach((value) => {
    exploreSpec.fields.push(pivotValueFieldToExploreField(value, seriesId));
  });

  return exploreSpec;
}

function pivotGroupByFieldToExploreField(
  field: PivotGroupByField,
  seriesId: ExploreSeriesId,
  channel: "row" | "column",
): ExploreField {
  return defaultExploreField({
    channel,
    dataType: fromPivotFieldType(field.fieldType ?? "UNKNOWN"),
    displayFormat: field.displayFormat,
    value: field.field,
    truncUnit: pivotTruncUnitToHqlTruncUnit(field.truncateTo),
    seriesId,
  });
}

function exploreFieldToPivotGroupByField(
  field: ExploreField,
): PivotGroupByField {
  if (isAggregatedField(field)) {
    return {
      field: generateColumnIdForField(field),
      fieldType: toPivotFieldType(field.dataType),
    };
  } else {
    return {
      field: generateColumnIdForField(field),
      fieldType: toPivotFieldType(field.dataType),
      truncateTo: hqlTruncUnitlToPivotTruncUnit(field.truncUnit),
      displayFormat: field.displayFormat,
    };
  }
}

function pivotValueFieldToExploreField(
  field: PivotValueField,
  seriesId: ExploreSeriesId,
): ExploreField {
  return defaultExploreField({
    channel: "value",
    dataType: fromPivotFieldType(field.fieldType ?? "UNKNOWN"),
    displayFormat: field.displayFormat,
    value: field.field,
    aggregation: pivotAggToHqlAgg(field.aggregation) ?? "Count",
    seriesId,
  });
}

function exploreFieldToPivotValueField(field: ExploreField): PivotValueField {
  if (isAggregatedField(field)) {
    return {
      field: generateColumnIdForField(field),
      fieldType: toPivotFieldType(field.dataType),
      // TODO(EXPLORE): EXP-1210 - field.aggregation will be null for measures, we need to
      // figure out how to represent meaures in the pivot config.
      aggregation: hqlAggToPivotAgg(field.aggregation) ?? "COUNT",
      displayFormat: field.displayFormat,
      // for measures, then name of the value field is just the name of the measure (not <measure_name>_<agg>)
      nameOverride:
        field.fieldType === ExploreFieldType.MEASURE ? field.value : undefined,
    };
  } else {
    return {
      field: generateColumnIdForField(field),
      fieldType: toPivotFieldType(field.dataType),
      displayFormat: field.displayFormat,
      aggregation: "COUNT",
    };
  }
}

function toPivotFieldType(type: CalciteType): PivotFieldInputType {
  return calciteTypeToColumnType(type);
}

function fromPivotFieldType(type: PivotFieldInputType): CalciteType {
  return columnTypeToCalciteType(type);
}

export function hqlAggToPivotAgg(
  agg: HqlAggregationFunction | undefined | null,
): PivotAggregation | null {
  if (agg == null) return "COUNT";

  switch (agg) {
    case "Min":
      return "MIN";
    case "Max":
      return "MAX";
    case "Avg":
      return "AVERAGE";
    case "Count":
      return "COUNT";
    case "CountDistinct":
      return "COUNT_DISTINCT";
    case "Sum":
      return "SUM";
    case "Median":
      return "MEDIAN";
    // below are not supported so default to count which works for all types
    case "StdDev":
    case "StdDevPop":
    case "Variance":
    case "VariancePop":
      return null;
    default:
      assertNever(agg, agg);
  }
}

export function pivotAggToHqlAgg(
  agg: PivotAggregation | undefined,
): HqlAggregationFunction | undefined {
  if (agg == null) return undefined;

  switch (agg) {
    case "MIN":
      return "Min";
    case "MAX":
      return "Max";
    case "AVERAGE":
      return "Avg";
    case "COUNT":
      return "Count";
    case "COUNT_DISTINCT":
      return "CountDistinct";
    case "SUM":
      return "Sum";
    case "MEDIAN":
      return "Median";
    default:
      assertNever(agg, agg);
  }
}

function hqlTruncUnitlToPivotTruncUnit(
  timeUnit: HqlTruncUnit | undefined | null,
): PivotTruncateDateInterval | undefined {
  if (timeUnit == null) {
    return undefined;
  }

  switch (timeUnit) {
    case "year":
      return "YEAR";
    case "quarter":
      return "QUARTER";
    case "month":
      return "MONTH";
    case "week":
      return "WEEK";
    case "day":
      return "DAY";
    case "hour":
      return "HOUR";
    // below are not supported, defaulting to closest
    case "dayofweek":
    case "minute":
    case "second":
      return "DAY";
    default:
      assertNever(timeUnit, timeUnit);
  }
}

export function pivotTruncUnitToHqlTruncUnit(
  timeUnit: PivotTruncateDateInterval | undefined,
): HqlTruncUnit | undefined {
  if (timeUnit == null) {
    return undefined;
  }

  switch (timeUnit) {
    case "YEAR":
      return "year";
    case "QUARTER":
      return "quarter";
    case "MONTH":
      return "month";
    case "WEEK":
      return "week";
    case "DAY":
      return "day";
    case "HOUR":
      return "hour";
    default:
      assertNever(timeUnit, timeUnit);
  }
}
