import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations/AtomicOperationDefinition.js";
import { CategoryId } from "../../idTypeBrands.js";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexAtomicOperationDefinition } from "../HexAtomicOperationDefinition.js";

const CREATE_HEX_CATEGORY_TYPE_LINK = "CREATE_HEX_CATEGORY_LINK" as const;
export const CREATE_HEX_CATEGORY_LINK = createHexAtomicOperationDefinition({
  type: CREATE_HEX_CATEGORY_TYPE_LINK,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "and",
    and: [
      {
        kind: "hasSemanticCap",
        cap: SemanticCap.EDIT_PROJECT_METADATA_FOR_WORKSPACE,
      },
      {
        kind: "hasSemanticCapOnIdArg",
        cap: SemanticCap.VIEW_ORG_PUBLIC,
        idArg: "categoryId",
        idType: "Category",
      },
    ],
  },
  logSafe: ["categoryId"],
  alwaysSkipUndoBuffer: true,
  conflictId: (op) =>
    `${CREATE_HEX_CATEGORY_TYPE_LINK}-${op.payload.categoryId}`,
  create: (args: { categoryId: CategoryId }) => ({
    type: CREATE_HEX_CATEGORY_TYPE_LINK,
    payload: {
      ...args,
    },
  }),
});

export type CREATE_HEX_CATEGORY_LINK = ExtractAtomicOperationFromDefinition<
  typeof CREATE_HEX_CATEGORY_LINK
>;
