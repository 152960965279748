import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import {
  CellReferencesParseError,
  PythonCellReferencesV2,
} from "../../../code";
import { CodeCellId } from "../../../idTypeBrands";
import { makeUpdateCellContentsOperation } from "../makeUpdateCellContentsOperation";

export interface CodeCellUpdatableFields {
  source: string;
  cellReferencesV2: PythonCellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
}

const _UpdateCodeCellSafeFields: {
  [key in keyof CodeCellUpdatableFields]: "";
} = {
  source: "",
  cellReferencesV2: "",
  cellReferencesParseError: "",
};
export const UpdateCodeCellSafeFields = Object.keys(_UpdateCodeCellSafeFields);

export const UPDATE_CODE_CELL = makeUpdateCellContentsOperation<
  CodeCellUpdatableFields,
  CodeCellId
>()({
  type: "UPDATE_CODE_CELL",
  entityName: "CodeCell",
  fieldName: "codeCellId",
});
export type UPDATE_CODE_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_CODE_CELL
>;
