/**
 * General utility functions for error handling
 */

import type { EnumValues } from "./enums";

export class UnhandledUnionMemberError extends Error {
  constructor(value: unknown | undefined) {
    super(`Unhandled discriminated union member: ${value}`);
  }
}

export interface TraceIdError extends Error {
  readonly traceId: string;
}

export function isTraceIdError(err: Error): err is TraceIdError {
  return typeof (err as { traceId?: string }).traceId === "string";
}

export function addTraceIdToError({
  err,
  traceId,
}: {
  traceId: string;
  err: Error;
}): TraceIdError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (err as any).traceId = traceId;
  return err as TraceIdError;
}

export function createTraceIdError({
  message,
  traceId,
}: {
  traceId: string;
  message: string;
}): TraceIdError {
  const err = new Error(message);
  return addTraceIdToError({ traceId, err });
}

/**
 * Causes a compile time error if `value` can have a valid type.
 * If somehow called at runtime, throws an error.
 *
 * Useful in the default case of matching against a discriminated union
 * to make sure the check is exhaustive.
 * See https://www.typescriptlang.org/docs/handbook/advanced-types.html for more info
 *
 * @param value the actual union being matched against
 * @param safeVariant a value safe to exfil that indicates what branch of the union is unknown
 */
export function assertNever(
  _value: never,
  safeVariant: unknown | undefined,
): never {
  throw new UnhandledUnionMemberError(safeVariant);
}

/**
 * Causes a compile time error similiar to `assertNever`, however,
 * it does not throw an error at runtime to allow easier recovery.
 *
 * Useful in similiar cases as `assertNever`, but when an unknown union
 * branch is more likely. For example, client and server version mismatch.
 *
 * @param value the actual union being matched against
 * @param safeVariant a value safe to exfil that indicates what branch of the union is unknown
 * @returns an instance of `UnhandledUnionMemberError`
 */
export function guardNever(
  _value: never,
  safeVariant: unknown | undefined,
): Error {
  return new UnhandledUnionMemberError(safeVariant);
}

/**
 * Default or custom GraphQL error codes that we may want to check for and handle specifically
 * Default error codes for apollo can be found here:
 * @link https://www.apollographql.com/docs/apollo-server/data/errors/#built-in-error-codes
 */
export const GraphQLErrorCode = {
  BAD_USER_INPUT: "BAD_USER_INPUT",
  FORBIDDEN: "FORBIDDEN",
  GRAPHQL_PARSE_FAILED: "GRAPHQL_PARSE_FAILED",
  GRAPHQL_VALIDATION_FAILED: "GRAPHQL_VALIDATION_FAILED",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  MAGIC_OPENAI_ERROR: "MAGIC_OPENAI_ERROR",
  MAGIC_OVERLOADED_ERROR: "MAGIC_OVERLOADED_ERROR",
  MAGIC_TOO_MANY_TOKENS: "MAGIC_TOO_MANY_TOKENS",
  MAGIC_OVER_USAGE: "MAGIC_OVER_USAGE",
  PERSISTED_QUERY_NOT_FOUND: "PERSISTED_QUERY_NOT_FOUND",
  PERSISTED_QUERY_NOT_SUPPORTED: "PERSISTED_QUERY_NOT_SUPPORTED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  USER_FACING_ERROR: "USER_FACING_ERROR",
} as const;
export type GraphQLErrorCode = EnumValues<typeof GraphQLErrorCode>;
