import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import { SecondaryCalculations } from "../../../dbt-metric/generated/dbtSecondaryCalculations.js";
import { DataConnectionId, DbtMetricCellId } from "../../../idTypeBrands";
import { makeUpdateCellContentsOperation } from "../makeUpdateCellContentsOperation";

export interface DbtMetricCellUpdatableFields {
  /**
   * perms on this are manually checked server side
   */
  connectionId: DataConnectionId | null;
  resultVariable: string;
  useRichDisplay: boolean;
  castDecimals: boolean;
  selectedTimegrain: string | null;
  selectedDimensions: string[] | null;
  startDate: Date | null;
  endDate: Date | null;
  secondaryCalculations: SecondaryCalculations[];
  useNativeDates: boolean;
}

const _UpdateDbtMetricCellSafeFields: {
  [key in keyof DbtMetricCellUpdatableFields]: "";
} = {
  connectionId: "",
  resultVariable: "",
  useRichDisplay: "",
  castDecimals: "",
  selectedTimegrain: "",
  selectedDimensions: "",
  startDate: "",
  endDate: "",
  secondaryCalculations: "",
  useNativeDates: "",
};
export const UpdateDbtMetricCellSafeFields = Object.keys(
  _UpdateDbtMetricCellSafeFields,
);

export const UPDATE_DBT_METRIC_CELL = makeUpdateCellContentsOperation<
  DbtMetricCellUpdatableFields,
  DbtMetricCellId
>()({
  type: "UPDATE_DBT_METRIC_CELL",
  entityName: "DbtMetricCell",
  fieldName: "dbtMetricCellId",
});
export type UPDATE_DBT_METRIC_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_DBT_METRIC_CELL
>;
