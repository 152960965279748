import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import { BlockConfig } from "../../../blockCell";
import { BlockCellId } from "../../../idTypeBrands";
import { makeUpdateCellContentsOperation } from "../makeUpdateCellContentsOperation";

export interface BlockCellUpdatableFields {
  blockConfig: BlockConfig | null;
}

const _UpdateBlockCellSafeFields: {
  [key in keyof BlockCellUpdatableFields]: "";
} = { blockConfig: "" };
export const UpdateBlockCellSafeFields = Object.keys(
  _UpdateBlockCellSafeFields,
);

export const UPDATE_BLOCK_CELL = makeUpdateCellContentsOperation<
  BlockCellUpdatableFields,
  BlockCellId,
  {
    runCell?: boolean;
  }
>()({
  type: "UPDATE_BLOCK_CELL",
  entityName: "BlockCell",
  fieldName: "blockCellId",
});
export type UPDATE_BLOCK_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_BLOCK_CELL
>;
