import { ExtractAtomicOperationFromDefinition } from "../atomic-operations/AtomicOperationDefinition";
import { CellReferencesParseError, CellReferencesV2 } from "../code";
import {
  CalcsDefinition,
  ColumnAggregationsDefinition,
  ColumnDisplayFormat,
  ConditionalFormattingDefinition,
  SortDirection,
  TableFiltersDefinition,
} from "../display-table";
import {
  CellId,
  ChartCellId,
  DbtMetricCellId,
  DisplayTableColumnId,
  DisplayTableConfigId,
  SqlCellId,
} from "../idTypeBrands";
import { SemanticCap } from "../semanticCaps.js";
import { typedObjectKeys } from "../utils/typedObjects.js";

import { CREATE_CELL } from "./cell";
import { createHexVersionAtomicOperationDefinition } from "./HexVersionAtomicOperationDefinition";

export interface DisplayTableConfigUpdatableFields {
  pageSize: number;
  height: number;
  hideIcons: boolean;
  defaultColumnWidth: number | null;
  hideIndex: boolean;
  sortByColumnDefault: string | null;
  sortByIndexColumnDefault: number | null;
  sortDirectionDefault: SortDirection;
  calcs: CalcsDefinition | null;
  pivotColumnOrdering: ReadonlyArray<SortDirection | null> | null;
  customColumnOrdering: ReadonlyArray<DisplayTableColumnId> | null;
  conditionalFormatting: ConditionalFormattingDefinition | null;
  conditionalFormattingReferences: CellReferencesV2 | null;
  conditionalFormattingReferencesParseError: CellReferencesParseError | null;
  filters: TableFiltersDefinition | null;
  filtersReferences: CellReferencesV2 | null;
  filtersReferencesParseError: CellReferencesParseError | null;
  pinnedColumns: ReadonlyArray<string> | null;
  hiddenColumns: ReadonlyArray<string> | null;
  pinIndexColumns: boolean;
  showAggregations: boolean;
  columnAggregations: ColumnAggregationsDefinition | null;
}

const _UpdateDisplayTableConfigSafeFields: {
  [key in keyof DisplayTableConfigUpdatableFields]: "";
} = {
  pageSize: "",
  height: "",
  hideIcons: "",
  defaultColumnWidth: "",
  hideIndex: "",
  sortByColumnDefault: "",
  sortByIndexColumnDefault: "",
  sortDirectionDefault: "",
  calcs: "",
  pivotColumnOrdering: "",
  conditionalFormatting: "",
  conditionalFormattingReferences: "",
  conditionalFormattingReferencesParseError: "",
  customColumnOrdering: "",
  filters: "",
  filtersReferences: "",
  filtersReferencesParseError: "",
  pinnedColumns: "",
  hiddenColumns: "",
  pinIndexColumns: "",
  showAggregations: "",
  columnAggregations: "",
};
export const UpdateDisplayTableConfigSafeFields = Object.keys(
  _UpdateDisplayTableConfigSafeFields,
);

const UPDATE_DISPLAY_TABLE_CONFIG_TYPE = "UPDATE_DISPLAY_TABLE_CONFIG" as const;
export const UPDATE_DISPLAY_TABLE_CONFIG =
  createHexVersionAtomicOperationDefinition({
    type: UPDATE_DISPLAY_TABLE_CONFIG_TYPE,
    readAuth: {
      kind: "hasSemanticCap",
      cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "cellId",
          idType: "Cell",
        },
        {
          kind: "hasSemanticCapOnIdArg",
          idArg: "displayTableConfigId",
          idType: "DisplayTableConfig",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
        },
      ],
    },
    logSafe: ["cellId", "key", "displayTableConfigId"],
    conflictId: (op) =>
      `${UPDATE_DISPLAY_TABLE_CONFIG_TYPE}-${op.payload.displayTableConfigId}-${op.payload.key}`,
    creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
    create: <K extends keyof DisplayTableConfigUpdatableFields>(args: {
      cellId: CellId;
      displayTableConfigId: DisplayTableConfigId;
      key: K;
      value: DisplayTableConfigUpdatableFields[K];
      suppressAutoRun?: boolean;
    }) => ({
      type: UPDATE_DISPLAY_TABLE_CONFIG_TYPE,
      payload: {
        ...args,
      },
    }),
  });

export type UPDATE_DISPLAY_TABLE_CONFIG = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_DISPLAY_TABLE_CONFIG
>;

const CREATE_DISPLAY_TABLE_CONFIG_TYPE =
  "CREATE_DISPLAY_TABLE_CONFIG_TYPE" as const;
export const CREATE_DISPLAY_TABLE_CONFIG =
  createHexVersionAtomicOperationDefinition({
    type: CREATE_DISPLAY_TABLE_CONFIG_TYPE,
    readAuth: {
      kind: "hasSomeOfSemanticCaps",
      someOfCaps: [
        SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
        SemanticCap.VIEW_PROJECT_CONTENTS_FOR_APP,
      ],
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "cellId",
          idType: "Cell",
        },
        {
          kind: "idArgDoesNotExist",
          idArg: "displayTableConfigId",
          idType: "DisplayTableConfig",
        },
      ],
    },
    logSafe: ["cellId", "displayTableConfigId"],
    conflictId: (op) =>
      `${CREATE_DISPLAY_TABLE_CONFIG_TYPE}-${op.payload.displayTableConfigId}`,
    creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
    create: (args: {
      cellId: CellId;
      displayTableConfigId: DisplayTableConfigId;
      suppressAutoRun?: boolean;
      filters?: TableFiltersDefinition;
    }) => ({
      type: CREATE_DISPLAY_TABLE_CONFIG_TYPE,
      payload: {
        ...args,
      },
    }),
  });

export type CREATE_DISPLAY_TABLE_CONFIG = ExtractAtomicOperationFromDefinition<
  typeof CREATE_DISPLAY_TABLE_CONFIG
>;

const CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG_TYPE =
  "CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG" as const;
export const CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG =
  createHexVersionAtomicOperationDefinition({
    type: CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG_TYPE,
    readAuth: {
      kind: "hasSemanticCap",
      cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "cellId",
          idType: "Cell",
        },
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "sqlCellId",
          idType: "SqlCell",
        },
        {
          kind: "idArgDoesNotExist",
          idArg: "displayTableConfigId",
          idType: "DisplayTableConfig",
        },
      ],
    },
    logSafe: ["cellId", "displayTableConfigId", "sqlCellId"],
    conflictId: (op) =>
      `${CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG_TYPE}-${op.payload.sqlCellId}`,
    creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
    create: (args: {
      cellId: CellId;
      displayTableConfigId: DisplayTableConfigId;
      sqlCellId: SqlCellId;
      pageSize?: number;
    }) => ({
      type: CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG_TYPE,
      payload: {
        ...args,
      },
    }),
  });

export type CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG =
  ExtractAtomicOperationFromDefinition<
    typeof CREATE_SQL_CELL_DISPLAY_TABLE_CONFIG
  >;

const CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG_TYPE =
  "CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG" as const;
export const CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG =
  createHexVersionAtomicOperationDefinition({
    type: CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG_TYPE,
    readAuth: {
      kind: "hasSemanticCap",
      cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "cellId",
          idType: "Cell",
        },
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "dbtMetricCellId",
          idType: "DbtMetricCell",
        },
        {
          kind: "idArgDoesNotExist",
          idArg: "displayTableConfigId",
          idType: "DisplayTableConfig",
        },
      ],
    },
    logSafe: ["cellId", "displayTableConfigId", "dbtMetricCellId"],
    conflictId: (op) =>
      `${CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG_TYPE}-${op.payload.dbtMetricCellId}`,
    creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
    create: (args: {
      cellId: CellId;
      displayTableConfigId: DisplayTableConfigId;
      dbtMetricCellId: DbtMetricCellId;
    }) => ({
      type: CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG_TYPE,
      payload: {
        ...args,
      },
    }),
  });

export type CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG =
  ExtractAtomicOperationFromDefinition<
    typeof CREATE_DBT_METRIC_CELL_DISPLAY_TABLE_CONFIG
  >;

export interface ColumnPropertiesUpdateableFields {
  displayFormat: ColumnDisplayFormat | null;
  size: number | null;
  wrapText: boolean;
  renameTo: string | null;
}
const _UpdateColumnPropertiesSafeFields: {
  [key in keyof ColumnPropertiesUpdateableFields]: "";
} = {
  displayFormat: "",
  size: "",
  wrapText: "",
  renameTo: "",
};
export const UpdateColumnPropertiesSafeFields = typedObjectKeys(
  _UpdateColumnPropertiesSafeFields,
);

const UPDATE_COLUMN_PROPERTIES_TYPE = "UPDATE_COLUMN_PROPERTIES" as const;
export const UPDATE_COLUMN_PROPERTIES =
  createHexVersionAtomicOperationDefinition({
    type: UPDATE_COLUMN_PROPERTIES_TYPE,
    readAuth: {
      kind: "hasSemanticCap",
      cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "cellId",
          idType: "Cell",
        },
        {
          kind: "hasSemanticCapOnIdArg",
          idArg: "displayTableConfigId",
          idType: "DisplayTableConfig",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
        },
      ],
    },
    logSafe: ["displayTableConfigId", "cellId", "key"],
    conflictId: (op) =>
      `${UPDATE_COLUMN_PROPERTIES_TYPE}-${op.payload.displayTableConfigId}-${op.payload.key}-${op.payload.originalName}`,
    creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
    create: <K extends keyof ColumnPropertiesUpdateableFields>(args: {
      displayTableConfigId: DisplayTableConfigId;
      cellId: CellId;
      originalName: DisplayTableColumnId;
      key: K;
      value: ColumnPropertiesUpdateableFields[K];
    }) => ({
      type: UPDATE_COLUMN_PROPERTIES_TYPE,
      payload: {
        ...args,
      },
    }),
  });

export type UPDATE_COLUMN_PROPERTIES = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_COLUMN_PROPERTIES
>;

const RENAME_COLUMN_PROPERTIES_TYPE = "RENAME_COLUMN_PROPERTIES" as const;
/**
 * @deprecated - use UPDATE_COLUMN_PROPERTIES in bulk instead
 */
export const RENAME_COLUMN_PROPERTIES =
  createHexVersionAtomicOperationDefinition({
    type: RENAME_COLUMN_PROPERTIES_TYPE,
    readAuth: {
      kind: "hasSomeOfSemanticCaps",
      someOfCaps: [
        SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
        SemanticCap.VIEW_PROJECT_CONTENTS_FOR_APP,
      ],
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "cellId",
          idType: "Cell",
        },
        {
          kind: "hasSemanticCapOnIdArg",
          idArg: "displayTableConfigId",
          idType: "DisplayTableConfig",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
        },
      ],
    },
    logSafe: ["displayTableConfigId", "cellId"],
    conflictId: (op) =>
      `${RENAME_COLUMN_PROPERTIES_TYPE}-${op.payload.displayTableConfigId}`,
    creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
    create: (args: {
      displayTableConfigId: DisplayTableConfigId;
      cellId: CellId;
      originalName: DisplayTableColumnId;
      newName: DisplayTableColumnId;
      suppressAutoRun?: boolean;
    }) => ({
      type: RENAME_COLUMN_PROPERTIES_TYPE,
      payload: {
        ...args,
      },
    }),
  });

/**
 * @deprecated - use UPDATE_COLUMN_PROPERTIES in bulk instead
 */
export type RENAME_COLUMN_PROPERTIES = ExtractAtomicOperationFromDefinition<
  typeof RENAME_COLUMN_PROPERTIES
>;

const CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG_TYPE =
  "CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG" as const;
export const CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG =
  createHexVersionAtomicOperationDefinition({
    type: CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG_TYPE,
    readAuth: {
      kind: "hasSemanticCap",
      cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
    },
    writeAuth: {
      kind: "and",
      and: [
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "cellId",
          idType: "Cell",
        },
        {
          kind: "hasSemanticCapOnIdArg",
          cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          idArg: "chartCellId",
          idType: "ChartCell",
        },
        {
          kind: "idArgDoesNotExist",
          idArg: "displayTableConfigId",
          idType: "DisplayTableConfig",
        },
      ],
    },
    logSafe: ["cellId", "displayTableConfigId", "chartCellId"],
    conflictId: (op) =>
      `${CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG_TYPE}-${op.payload.chartCellId}`,
    creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
    create: (args: {
      cellId: CellId;
      displayTableConfigId: DisplayTableConfigId;
      chartCellId: ChartCellId;
      skipCellRun?: boolean;
    }) => ({
      type: CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG_TYPE,
      payload: {
        ...args,
      },
    }),
  });

export type CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG =
  ExtractAtomicOperationFromDefinition<
    typeof CREATE_CHART_CELL_DISPLAY_TABLE_CONFIG
  >;
