import { AtomicOperation } from "../../atomic-operations";
import { CellId } from "../../idTypeBrands";
import { PermissionedEntityTypeName } from "../../PermissionedEntityTypeName";
import { SemanticCap } from "../../semanticCaps.js";
import {
  HexVersionAtomicOperationDefinitionExtras,
  createHexVersionAtomicOperationDefinition,
} from "../HexVersionAtomicOperationDefinition";

import { CREATE_CELL } from "./createCellOperation";

type MakeUpdateCellContentsOperationArgs<
  ACTION_TYPE extends `UPDATE_${string}_CELL`,
  ENTITY_NAME extends `${string}Cell`,
  FIELD_NAME extends `${Uncapitalize<ENTITY_NAME>}Id`,
> = {
  type: ACTION_TYPE;
  entityName: ENTITY_NAME;
  fieldName: FIELD_NAME;
};

/**
 * This function needs to be called twice - the first time with an explicit generic.
 * For example:
 *
 * ```
 * const UPDATE_CODE_CELL =
 *   makeUpdateCellContentsOperation<CodeCellUpdatableFields, CodeCellId>()({
 *     type: "UPDATE_CODE_CELL",
 *     entityName: "CodeCell",
 *     fieldName: "codeCellId",
 *   });
 * ```
 * This "double call" makes it so you can provide one explicit generic
 * without having to provide so many others.
 *
 * @returns An AtomicOperationDefinition for updating cell fields for a specific cell type
 */
// Inferring the return type gives us better inference for `create` args
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeUpdateCellContentsOperation =
  <
    UPDATABLE_FIELDS extends object,
    ID_TYPE extends string,
    ADDITIONAL_FIELD_ARGS extends object = {}, // eslint-disable-line @typescript-eslint/ban-types
  >() =>
  <
    ACTION_TYPE extends `UPDATE_${string}_CELL`,
    ENTITY_NAME extends PermissionedEntityTypeName & `${string}Cell`,
    FIELD_NAME extends `${Uncapitalize<ENTITY_NAME>}Id`,
  >({
    entityName,
    fieldName,
    type,
    ...extras
  }: MakeUpdateCellContentsOperationArgs<ACTION_TYPE, ENTITY_NAME, FIELD_NAME> &
    HexVersionAtomicOperationDefinitionExtras<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (...args: any[]) => AtomicOperation<
        string,
        {
          [K2 in typeof fieldName]: ID_TYPE;
        } & {
          cellId: CellId;
          key: keyof UPDATABLE_FIELDS;
          value: UPDATABLE_FIELDS[keyof UPDATABLE_FIELDS];
        } & ADDITIONAL_FIELD_ARGS
      >
    >) =>
    createHexVersionAtomicOperationDefinition({
      ...extras,
      type,
      readAuth: {
        kind: "hasSemanticCap",
        cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
      },
      writeAuth: {
        kind: "and",
        and: [
          {
            kind: "hasSemanticCapOnIdArg",
            cap: SemanticCap.EDIT_PROJECT_CONTENTS,
            idArg: "cellId",
            idType: "Cell",
          },
          {
            kind: "hasSemanticCapOnIdArg",
            idArg: fieldName,
            idType: entityName,
            cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          },
        ],
      },
      logSafe: ["cellId", fieldName, "key"],
      conflictId: (op) => `${type}-${op.payload[fieldName]}-${op.payload.key}`,
      creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
      create: <K extends string & keyof UPDATABLE_FIELDS>(
        args: {
          [K2 in typeof fieldName]: ID_TYPE;
        } & {
          cellId: CellId;
          key: K;
          value: UPDATABLE_FIELDS[K];
        } & ADDITIONAL_FIELD_ARGS,
      ) => ({
        type,
        payload: {
          ...args,
        },
      }),
    });
