/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AtomicOperationDefinition,
  ConstantOrPerOp,
  GenericAtomicOperation,
} from "../atomic-operations";

export interface HexVersionAtomicOperationDefinitionExtras<
  CREATE extends (...args: any[]) => GenericAtomicOperation,
> {
  /**
   * If true, don't include operations of this type in edit history
   *
   * @default false
   */
  excludeFromHistory?: ConstantOrPerOp<boolean, CREATE>;
  /**
   * If true, don't include extra operations returned by
   * the server when handling operations of this type in edit history
   *
   * @default false
   */
  excludeExtraOpsFromHistory?: ConstantOrPerOp<boolean, CREATE>;
}

export type HexVersionAtomicOperationDefinition<
  CREATE extends (...args: any[]) => GenericAtomicOperation,
> = AtomicOperationDefinition<
  CREATE,
  HexVersionAtomicOperationDefinitionExtras<CREATE>
>;

export const createHexVersionAtomicOperationDefinition = <
  CREATE extends (...args: any[]) => GenericAtomicOperation,
>(
  baseDef: AtomicOperationDefinition<
    CREATE,
    HexVersionAtomicOperationDefinitionExtras<CREATE>
  >,
): AtomicOperationDefinition<
  CREATE,
  HexVersionAtomicOperationDefinitionExtras<CREATE>
> => baseDef;
