import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations";
import { DateTimeString } from "../../dateTypes";
import { CellId, StaticCellId } from "../../idTypeBrands";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

import {
  CREATE_CELL,
  CellContentsPayload,
  StoryElementPayload,
} from "./createCellOperation";
import { DELETE_CELL_TYPE } from "./deleteCellOperation";

export const RESTORE_CELL_TYPE = "RESTORE_CELL" as const;
export const RESTORE_CELL = createHexVersionAtomicOperationDefinition({
  type: RESTORE_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_PROJECT_CONTENTS,
    idArg: "cellId",
    idType: "Cell",
  },
  logSafe: ["cellId"],
  conflictId: (op) =>
    `${DELETE_CELL_TYPE}-${RESTORE_CELL_TYPE}-${op.payload.cellId}`,
  creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
  excludeFromHistory: (op) => op.payload.excludeFromHistory ?? false,
  create: ({
    cellId,
    cellRestoreInfo = null,
    excludeFromHistory,
    initiatedByParentBlockCell,
  }: {
    cellId: CellId;
    // the server adds this optional argument
    // in case a new client joins that
    // can't restore using only the cellId
    cellRestoreInfo?: {
      staticId: StaticCellId;
      order: string;
      createdDate: DateTimeString;
      updatedDate: DateTimeString;
      revision: number;
      label?: string;
      cellContents: CellContentsPayload;
      storyElement: StoryElementPayload;
      parentCellId?: CellId;
    } | null;
    initiatedByParentBlockCell?: boolean;
    excludeFromHistory?: boolean;
  }) => ({
    type: RESTORE_CELL_TYPE,
    payload: {
      cellId,
      cellRestoreInfo,
      initiatedByParentBlockCell: initiatedByParentBlockCell ?? false,
      excludeFromHistory,
    },
  }),
});

export type RESTORE_CELL = ExtractAtomicOperationFromDefinition<
  typeof RESTORE_CELL
>;
