import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations/AtomicOperationDefinition.js";
import { CellId, MagicEventId } from "../../idTypeBrands.js";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition.js";

export interface DisardChainPayload {
  id: MagicEventId;
  parentCellId: CellId;
}

const DISCARD_CHAIN_TYPE = "DISCARD_CHAIN" as const;
export const DISCARD_CHAIN = createHexVersionAtomicOperationDefinition({
  type: DISCARD_CHAIN_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.EDIT_PROJECT_CONTENTS,
  },
  logSafe: ["id"],
  conflictId: (op) => `${DISCARD_CHAIN_TYPE}-${op.payload.id}`,
  create: (args: DisardChainPayload) => ({
    type: DISCARD_CHAIN_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type DISCARD_CHAIN = ExtractAtomicOperationFromDefinition<
  typeof DISCARD_CHAIN
>;
