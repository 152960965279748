import {
  Array,
  Boolean,
  Dictionary,
  Lazy,
  Literal,
  Null,
  Number,
  Optional,
  Record,
  Runtype,
  Static,
  String,
  Union,
} from "runtypes";

import {
  BinaryColumnPredicateOpLiteral,
  CompoundColumnPredicateOp,
  CompoundColumnPredicateOpLiteral,
  UnaryColumnPredicateOpLiteral,
} from "../display-table";
import { FilledDynamicValueTableColumnTypeLiteral } from "../DynamicValue";
import { FilterCellColumnId } from "../idTypeBrands";
import { getNormalEnum } from "../runtypeEnums";

export const FilterCellSpecificUnaryColumnPredicateOpLiteral = Union(
  Literal("IS_EMPTY"),
  Literal("NOT_EMPTY"),
);

export const FilterCellUnaryColumnPredicateOpLiteral = Union(
  FilterCellSpecificUnaryColumnPredicateOpLiteral,
  UnaryColumnPredicateOpLiteral,
);
export type FilterCellUnaryColumnPredicateOp = Static<
  typeof FilterCellUnaryColumnPredicateOpLiteral
>;
export const FilterCellUnaryColumnPredicateOp = {
  ...getNormalEnum(FilterCellSpecificUnaryColumnPredicateOpLiteral),
  ...getNormalEnum(UnaryColumnPredicateOpLiteral),
};
export const FilterCellUnaryColumnPredicate = Record({
  op: FilterCellUnaryColumnPredicateOpLiteral,
});
export type FilterCellUnaryColumnPredicate = Static<
  typeof FilterCellUnaryColumnPredicate
>;

export const FilterCellBinaryColumnPredicateOpLiteral =
  BinaryColumnPredicateOpLiteral;

export type FilterCellBinaryColumnPredicateOp = Static<
  typeof FilterCellBinaryColumnPredicateOpLiteral
>;

export const FilterCellBinaryColumnPredicateOp = {
  ...getNormalEnum(BinaryColumnPredicateOpLiteral),
};

export const FilterCellBinaryColumnPredicate = Record({
  op: FilterCellBinaryColumnPredicateOpLiteral,
  arg: String,
  // `allowInterpolation` is omitted on the persisted db representation and added when
  // passed them into the kernel. In practice this is used to to determine whether to
  // support jinja templating in filters.
  allowInterpolation: Boolean.optional(),
});
export type FilterCellBinaryColumnPredicate = Static<
  typeof FilterCellBinaryColumnPredicate
>;

export const FilterCellListBinaryColumnPredicateOpLiteral = Union(
  Literal("IS_ONE_OF"),
  Literal("NOT_ONE_OF"),
  Literal("DATE_BETWEEN"),
);

export type FilterCellListBinaryColumnPredicateOp = Static<
  typeof FilterCellListBinaryColumnPredicateOpLiteral
>;

export const FilterCellListBinaryColumnPredicateOp = getNormalEnum(
  FilterCellListBinaryColumnPredicateOpLiteral,
);

export const FilterCellListBinaryColumnPredicate = Record({
  op: FilterCellListBinaryColumnPredicateOpLiteral,
  arg: Array(String),
  // `allowInterpolation` is omitted on the persisted db representation and added when
  // passed them into the kernel. In practice this is used to to determine whether to
  // support jinja templating in filters.
  allowInterpolation: Boolean.optional(),
});

export type FilterCellListBinaryColumnPredicate = Static<
  typeof FilterCellListBinaryColumnPredicate
>;

export type FilterCellColumnPredicate =
  | FilterCellBinaryColumnPredicate
  | FilterCellUnaryColumnPredicate
  | FilterCellListBinaryColumnPredicate;

export type FilterCellColumnPredicateOp = FilterCellColumnPredicate["op"];

export const ColumnOperation = Union(
  FilterCellUnaryColumnPredicate,
  FilterCellBinaryColumnPredicate,
  FilterCellListBinaryColumnPredicate,
);

export type ColumnOperation = Static<typeof ColumnOperation>;

export const Filter = Record({
  column: FilterCellColumnId,
  operation: ColumnOperation,
  columnType: FilledDynamicValueTableColumnTypeLiteral,
  matchCase: Optional(Boolean),
});
export type Filter = Static<typeof Filter>;

export type FilterGroup = {
  operation: CompoundColumnPredicateOp;
  filters: (Filter | FilterGroup)[];
};

export const FilterGroup: Runtype<FilterGroup> = Lazy(() =>
  Record({
    operation: CompoundColumnPredicateOpLiteral,
    filters: Array(Union(Filter, FilterGroup)),
  }),
);

export const FilterTypeLiteral = Union(Literal("KEEP"), Literal("REMOVE"));
export type FilterType = Static<typeof FilterTypeLiteral>;
export const FilterType = getNormalEnum(FilterTypeLiteral);

export const FilterCellAppMode = {
  FULL_ACCESS: "FULL_ACCESS",
  VALUES_ONLY: "VALUES_ONLY",
} as const;
export type FilterCellAppMode =
  (typeof FilterCellAppMode)[keyof typeof FilterCellAppMode];

export const DistinctColumnValues = Dictionary(
  Record({
    value: Array(Union(String, Null, Number, Boolean)),
    truncated: Boolean,
  }),
  String,
);

export type DistinctColumnValues = Static<typeof DistinctColumnValues>;
