/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AtomicOperationDefinition,
  ConstantOrPerOp,
  GenericAtomicOperation,
} from "../atomic-operations/index.js";

export interface HexAtomicOperationDefinitionExtras<
  CREATE extends (...args: any[]) => GenericAtomicOperation,
> {
  /**
   * If true, don't include operations of this type in edit history
   *
   * @default false
   */
  excludeFromHistory?: ConstantOrPerOp<boolean, CREATE>;
  /**
   * If true, don't include extra operations returned by
   * the server when handling operations of this type in edit history
   *
   * @default false
   */
  excludeExtraOpsFromHistory?: ConstantOrPerOp<boolean, CREATE>;
}

export type HexAtomicOperationDefinition<
  CREATE extends (...args: any[]) => GenericAtomicOperation,
> = AtomicOperationDefinition<
  CREATE,
  HexAtomicOperationDefinitionExtras<CREATE>
>;

export const createHexAtomicOperationDefinition = <
  CREATE extends (...args: any[]) => GenericAtomicOperation,
>(
  baseDef: AtomicOperationDefinition<
    CREATE,
    HexAtomicOperationDefinitionExtras<CREATE>
  >,
): AtomicOperationDefinition<
  CREATE,
  HexAtomicOperationDefinitionExtras<CREATE>
> => baseDef;
