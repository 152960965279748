export const MAX_UPLOAD_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB

// TODO(file-format) make a separate, smaller upload limit for Hex project files?
// They don't include outputs so should be be smaller than .ipynb files.
export const MAX_PROJECT_IMPORT_FILE_SIZE = 50 * 1024 * 1024;

export const MAX_SIDECAR_REQUEST_PAYLOAD_SIZE = 20 * 1024 * 1024; // 20 MB

export const MAX_APP_ICON_FILE_SIZE = 30 * 1024; // 30 KB

export const MAX_SQL_CONFIG_FILE_SIZE = 30 * 1024; // 30 KB
