import { OrgRole } from "./enums.js";
import { ProductionEnvironment } from "./environment";

// Consolidated function that sets the default expected flag attributes when fetching LD flags either from client or
// server
export const getDefaultFlagAttributes = ({
  customerId,
  environment,
  orgRole,
  stackId,
}: {
  stackId: string;
  customerId?: string | undefined | null;
  environment: ProductionEnvironment;
  orgRole: OrgRole | undefined;
}): Record<string, string> => {
  const attributes: Record<string, string> = {
    stackId: stackId,
    env: environment,
  };

  if (customerId) {
    attributes["customerId"] = customerId;
  }

  if (orgRole) {
    attributes["orgRole"] = orgRole;
  }

  return attributes;
};
