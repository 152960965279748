// avoid non dev dep on graphql
import type { DocumentNode, OperationDefinitionNode } from "graphql";

export const getPersistedQueryHash = (
  queryNameToHash: Record<string, string | undefined>,
) => {
  return (doc: DocumentNode) => {
    const operationDefinition = doc.definitions.find<OperationDefinitionNode>(
      (def): def is OperationDefinitionNode =>
        def.kind === "OperationDefinition",
    );

    if (!operationDefinition) {
      throw new Error("Operation missing from graphql query");
    }

    if (!operationDefinition.name) {
      throw new Error("name missing from operation definition");
    }

    const hash = queryNameToHash[operationDefinition.name.value];

    if (!hash) {
      throw new Error(
        "Cannot find query hash has for " + operationDefinition.name.value,
      );
    }

    return hash;
  };
};
