import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import { CellReferencesParseError, CellReferencesV2 } from "../../../code";
import { MarkdownCellId } from "../../../idTypeBrands";
import { makeUpdateCellContentsOperation } from "../makeUpdateCellContentsOperation";

export interface MarkdownCellUpdatableFields {
  source: string;
  cellReferencesV2: CellReferencesV2 | null;
  cellReferencesParseError: CellReferencesParseError | null;
}

const _UpdateMarkdownCellSafeFields: {
  [key in keyof MarkdownCellUpdatableFields]: "";
} = {
  source: "",
  cellReferencesV2: "",
  cellReferencesParseError: "",
};
export const UpdateMarkdownCellSafeFields = Object.keys(
  _UpdateMarkdownCellSafeFields,
);

export const UPDATE_MARKDOWN_CELL = makeUpdateCellContentsOperation<
  MarkdownCellUpdatableFields,
  MarkdownCellId
>()({
  type: "UPDATE_MARKDOWN_CELL",
  entityName: "MarkdownCell",
  fieldName: "markdownCellId",
});
export type UPDATE_MARKDOWN_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_MARKDOWN_CELL
>;
