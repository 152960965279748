import { ChartDataType } from "../chart/types.js";
import { DisplayTableColumnOutputType } from "../display-table/outputTypes.js";
import { guardNever } from "../errors.js";
import {
  CalciteType,
  HqlAggregationFunction,
  HqlTruncUnit,
} from "../hql/types.js";
import { uuid } from "../uuid.js";

import {
  ExploreChannel,
  ExploreChartSeries,
  ExploreField,
  ExploreFieldId,
  ExploreFieldType,
  ExploreSeriesId,
  ExploreSort,
  ExploreSpec,
} from "./types.js";

export function defaultExploreSpec(noSeries?: boolean): ExploreSpec {
  const initialSeries = defaultExploreChartSeries();
  return {
    fields: [],
    details: {
      fields: [],
      enabled: true,
      showAllBaseTableDetailFields: true,
    },
    visualizationType: "chart",
    viewType: "both",
    chartConfig: {
      orientation: "vertical",
      series: noSeries ? [] : [initialSeries],
      seriesGroups: noSeries ? [] : [[initialSeries.id]],
      settings: {
        legend: {
          position: "right",
        },
      },
    },
  };
}

export function defaultExploreChartSeries(
  type: "bar" | "line" = "bar",
): ExploreChartSeries {
  return { id: ExploreSeriesId.check(uuid()), type };
}

export const defaultExploreAggregation = (
  channel: ExploreChannel,
  type: DisplayTableColumnOutputType,
  fieldType: ExploreFieldType | undefined,
): ExploreField["aggregation"] => {
  if (fieldType === ExploreFieldType.MEASURE) {
    // measures have their own aggregation definition, so no aggregation is set
    // on the explore field
    return undefined;
  }
  switch (channel) {
    case "cross-axis":
    case "opacity":
      return type === "NUMBER" ? "Sum" : "Count";

    case "tooltip":
    case "color":
      return type === "NUMBER" ? "Sum" : undefined;

    case "base-axis":
    case "h-facet":
    case "v-facet":
      return undefined;

    case "row":
    case "column":
      return undefined;
    case "value":
      return type === "NUMBER" ? "Sum" : "Count";

    default:
      guardNever(channel, channel);
      return undefined;
  }
};

export function defaultExploreField({
  aggregation,
  channel,
  dataType,
  displayFormat,
  fieldType,
  queryPath,
  scaleType,
  seriesId,
  sort,
  title,
  truncUnit,
  value,
}: {
  channel: ExploreChannel;
  value: string;
  seriesId: ExploreSeriesId;
  dataType: CalciteType;
  scaleType?: ChartDataType;
  displayFormat?: ExploreField["displayFormat"];
  aggregation?: HqlAggregationFunction;
  truncUnit?: HqlTruncUnit;
  fieldType?: ExploreFieldType;
  queryPath?: string[];
  sort?: ExploreSort;
  title?: string;
}): ExploreField {
  return {
    id: ExploreFieldId.check(uuid()),
    channel: channel,
    seriesId: seriesId,
    value: value,
    dataType,
    scaleType,
    truncUnit,
    displayFormat,
    aggregation,
    fieldType: fieldType ?? ExploreFieldType.COLUMN,
    queryPath: queryPath ?? [],
    sort,
    title,
  };
}
